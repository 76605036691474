.accordion {

}
.accordion-item {
  list-style: none;
}
.accordion-toggle {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background: var(--clr-bg-white);
  border-bottom: 1px solid var(--clr-bg-white);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 20px;
  color: var(--clr-common-heading);
  font-weight: 700;
  font-family: "Urbanist",sans-serif;
  padding: 28px 20px 28px 30px;
}

.plus {
  margin-right: 10px;
}

.accordion-toggle h3 {
  margin: 0;
}
.accordion-content {
  background-color: #eee;
  padding: 1em;
}