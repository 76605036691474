
.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selects-filters-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}