.header1 {
  width: 100%;
  background: #eff1f5;
}

.header-main1 {
  border-bottom: 1px solid var(--clr-common-border);
  overflow-x: clip;
}

.container.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1650px;
  width: 100%;
  padding: 16px;
}

.header-main-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 50%;
  
  @media screen and (max-width: 1400px) {
    width: 100%;
  }
}

.header-logo {
  max-width: 210px;
}

.logo-img {
  width: 100%;
}

.header-main2-content .header-main-right {
  display: flex;
  align-items: center
}

.header-main-right {
  display: flex;
  align-items: center;
  justify-content: end
}

.header-btn {
  position: relative;
  margin-left: 20px;

  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.main-menu ul li span {
  font-size: 18px;
  color: var(--clr-common-heading);
  text-transform: inherit;
  display: inline-block;
  line-height: 20px;
  font-weight: 600;
  cursor: pointer;
}

.menu-item:hover {
  color: #20d083 !important;
}

.main-menu ul li .sub-menu {
  background: var(--clr-bg-white) none repeat scroll 0 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, .176);
  left: 0;
  opacity: 0;
  position: absolute;
  top: 120%;
  transition: all .3s ease 0s;
  visibility: hidden;
  width: 240px;
  z-index: 9;
  border-top: 4px solid var(--clr-theme-1);
  text-align: left;
  padding: 15px 0
}

.main-menu ul li .sub-menu .sub-menu {
  left: 100%;
  top: 100%
}

.main-menu ul li .sub-menu li {
  display: inline-flex;
  margin: 0px;
  padding: 8px 25px 8px 25px;
  width: 100%;
  align-items: center;
  justify-content: space-between
}

.main-menu ul li .sub-menu li a {
  padding: 0;
  display: block;
  color: var(--clr-common-heading);
  position: relative;
  font-weight: 400
}

.main-menu ul li .sub-menu li a:before {
  content: "";
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  transition: width .6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: var(--clr-common-heading);
  opacity: 0
}

.main-menu ul li .sub-menu li:hover > a {
  color: var(--clr-theme-1);
  padding-left: 5px
}

.main-menu ul li .sub-menu li:hover > a:before {
  width: 100%;
  left: 0;
  right: auto
}

.main-menu ul li:hover > a {
  color: var(--clr-theme-1)
}

.main-menu ul li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%
}

.main-menu ul li:hover > .sub-menu li:hover > .sub-menu {
  top: 0
}

.main-menu ul li:hover.menu-item-has-children::after {
  color: var(--clr-theme-1);
  opacity: 1;
  visibility: visible
}

.main-menu ul li.menu-item-has-children {
  position: relative
}

.main-menu ul li.menu-item-has-children:after {
  position: static;
  content: "";
  font-size: 12px;
  color: var(--clr-common-heading);
  opacity: 1;
  visibility: visible;
  font-family: "Font Awesome 5 Pro", serif;
  transition: all .3s ease-out 0s;
  margin-left: 2px
}

.main-menu1 {
  margin-right: 95px
}

.main-menu {
  @media screen and (max-width: 1400px){
    display: none;
  }
}

.d-lg-block {
  display: block !important;
}

.filter-search-input.header-search {
  width: 100%;

  @media screen and (max-width: 800px) {
    display: none;
  }
}

.filter-search-input.header-search input {
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  background: none;
  padding: 0 50px 0 20px;
  color: var(--clr-common-heading)
}

.filter-search-input.header-search button {
  top: 9px;
  color: #8b8d8f
}

.filter-search-input.header-search button::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 20px;
  background: var(--clr-common-border);
  top: 50%;
  transform: translateY(-50%);
  left: -18px
}

.profile-item {
  margin-left: 20px;
}

.profile-item-header .profile-img {
  position: relative;
  cursor: pointer
}

.profile-item-header .profile-img:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 1;
  border-radius: 50%
}

.profile-item-header .profile-img img {
  width: 45px;
  height: 45px;
  border: 0;
  cursor: pointer
}

.profile-item-header .profile-verification {
  width: 18px;
  height: 18px;
  border-width: 2px;
  line-height: 16px;
  font-size: 7px;
  right: 0;
  bottom: 0
}

.pos-rel {
  position: relative;
}

.profile-action {
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.profile-action ul {
  background: var(--clr-bg-white);
  padding: 10px 15px;
  min-width: 120px;
  border-radius: 5px;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 99999;
  box-shadow: 0 30px 20px rgba(37, 52, 103, 0.11);
}

.profile-action ul li i {
  margin-right: 10px;
  font-size: 12px;
}

.profile-action ul li a {
  font-size: 16px;
  font-weight: 500;
  display: block;
  padding: 5px 0;
}

.profile-action ul li a:hover {
  color: var(--clr-theme-1);
}

.menu-bar {
  display: none;
  margin-left: 20px;
}

@media screen and (max-width: 1400px) {
  .menu-bar {
    display: flex;
  }
}

@media only screen and (min-width: 1400px)and (max-width: 1599px), only screen and (min-width: 1200px)and (max-width: 1399px) {
  .main-menu1 {
    margin-right: 30px
  }
}

@media only screen and (min-width: 992px)and (max-width: 1199px) {
  .main-menu1 {
    margin-right: 50px
  }
}


@media only screen and (min-width: 576px)and (max-width: 767px),(max-width: 575px) {
  .header1-logo img {
    max-width: 160px
  }
}

