.creator-details-area {
  padding-top: 0;
  padding-bottom: 90px;
  background: #eff1f5;
}

.creator-info-bar {
  margin-bottom: 30px;
}

.count-of-nft {
  color: #20d083 !important;
  margin-left: 5px;
}

.creator-info-tab-nav {
  margin-bottom: 30px;
}

.creator-info-tab-contents {
  margin-bottom: 30px;
}