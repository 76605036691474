.loading-logo-img {
  z-index: 1;
  width: 100px;
  height: 100px;
}

.loading-icon {
  position: relative;
}

.loading-logo {
  z-index: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}