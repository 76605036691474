.about-info-area  {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #eff1f5;
}
.container {
}

.faq-note {
  margin-top: 10px;
  margin-bottom: 30px;
}

.faq-tab {
  margin-bottom: 10px;
}