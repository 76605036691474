.artist-username{
  font-size: 14px;
  line-height: 1;
  color: #636363;
  font-weight: 500;
}

.artwork-name {
  font-size: 22px;
  color: #000;
  margin-bottom: 15px;
  font-weight: 700;
}

.art-price {
  font-size: 18px;
  font-weight: 700;
  color: #20d083;
  line-height: 1;
}

.art-meta-type {
  font-size: 15px;
  font-weight: 500;
  color: #636363;
  line-height: 1;
  margin-bottom: 5px;
}

.art-item-img {
  overflow: hidden;
}

.home-art-image {
  width: 100%;
  height: 200px;
}