.artworks-area {
  background: #eff1f5;
  padding-top: 130px;
  padding-bottom: 90px;
}

.select-category-wrapper{
  width: 220px;
  height: 50px;
}

.art-filter-row {
  margin-bottom: 40px;
}