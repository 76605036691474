.sidebar-logo {
  margin-bottom: 40px;
}

.menu-link {
  width: 100%;
  color: var(--clr-common-white);
  border-top: 1px solid #191c1f !important;
  font-size: 16px;
  opacity: 1;
  display: block;
  padding: 8px 0;
  margin: 0;
  text-align: left;
  border-top: 1px solid #e0e3ed;
  cursor: pointer;
}

.connect-button {
  display: none;

  @media screen and (max-width: 1000px){
    display: flex;
  }
}
