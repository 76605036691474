.name-and-surname {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 4px;
}

.artist-username {
  cursor: pointer;
}

.artist-id:hover {
  cursor: pointer;
}