.creator-info-area {
  background: #eff1f5;
  padding-top: 50px;
  padding-bottom: 50px;
}

.artist-id {
  color: #20d083 !important;
}

.creator-cover-img {
  width: 100%
}

.no-cover {
  height: 160px;
  width: 100%;
  border-radius: 10px 10px 0 0;
  object-fit: cover;
  background: #b4b4b4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-image {
  width: 120px;
  height: 130px;
  border-radius: 10px;
  margin-top: -45px;
  background: #b4b4b4;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  text-align: center;
}

.file-select > .select-button {
  position: absolute;
  right: 5px;
  bottom: 5px;
  width: 30px;
  height: 30px;
  background: #171717;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
}

.file-select > input[type="file"] {
  display: none;
}