.connect-wallet-area {
  background: #eff1f5;
  padding-top: 50px;
  padding-bottom: 50px;
}

.box-card-single {
  margin-bottom: 40px;
}

.box-card-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wallets-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}