.upload-wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #eff1f5;
}
.loader{
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100%;
}
.uploaded-files {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: start;
  gap: 10px;
}

.uploaded-file {
  img {
    width: 80px;
    height: 100px;
    border-radius: 8px;
  }
}

.sale-categoty-box {
  cursor: pointer;
  transition: all .3s;
  border: 2px solid #fff;

  &:hover {
    background: rgba(255, 255, 255, .8);
  }
}

.sale-categoty-box.active {
  border: 2px solid #20D083FF;
}
