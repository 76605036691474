@charset "UTF-8";

@font-face {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/urbanist/v8/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDlR4fFg.ttf) format('truetype');
}

@font-face {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/urbanist/v8/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDyx4fFg.ttf) format('truetype');
}

@font-face {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/urbanist/v8/L0xjDF02iFML4hGCyOCpRdycFsGxSrqD-R4fFg.ttf) format('truetype');
}

@font-face {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/urbanist/v8/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDFRkfFg.ttf) format('truetype');
}

@font-face {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/urbanist/v8/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDLBkfFg.ttf) format('truetype');
}

@font-face {
    font-family: 'Urbanist';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/urbanist/v8/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDSxkfFg.ttf) format('truetype');
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Urbanist", sans-serif;
    font-size: 16px;
    font-weight: normal;
    color: var(--clr-common-body-text);
    line-height: 28px;
}

a {
    text-decoration: none;
}

.w-img img {
    width: 100%;
}

.m-img img {
    max-width: 100%;
}

a,
.btn,
button,
span,
p,
i,
input,
select,
textarea,
li,
img,
svg path,
*::after,
*::before,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

a:focus{
    text-decoration: none;
    outline: none;
}

a:focus,
a:hover {
    color: inherit;
    text-decoration: none;
}

a,
button {
    color: inherit;
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none !important;
}

button:hover {
    cursor: pointer;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Urbanist", sans-serif;
    color: var(--clr-common-heading);
    margin-top: 0px;
    font-weight: 700;
    line-height: 1.2;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 14px;
}

ul, ol, dl {
    margin: 0 !important;
    padding: 0 !important;
}

li {
    list-style: none;
}

p {
    font-family: "Urbanist", sans-serif;
    font-size: 16px;
    font-weight: normal;
    color: var(--clr-common-body-text);
    margin-bottom: 15px;
    line-height: 28px;
}

*::-moz-selection {
    background: var(--clr-common-black);
    color: var(--clr-common-white);
    text-shadow: none;
}

::-moz-selection {
    background: var(--clr-common-black);
    color: var(--clr-common-white);
    text-shadow: none;
}

::selection {
    background: var(--clr-common-black);
    color: var(--clr-common-white);
    text-shadow: none;
}

/*--
    - SearchInput Placeholder
-----------------------------------------*/
*::-moz-placeholder {
    font-size: 16px;
    color: #b4b4b4;
    opacity: 1;
}

*::placeholder {
    font-size: 16px;
    color: #b4b4b4;
    opacity: 1;
}

:root {
    /**
     @color declaration
     */
    --clr-common-white: #ffffff;
    --clr-common-black: #000000;
    --clr-common-blue: #2785ff;
    --clr-common-heading: #171717;
    --clr-common-body-text: #636363;
    --clr-common-border: #e0e2e5;
    --clr-common-placeholder: #b4b4b4;
    --clr-theme-1: #2785ff;
    --clr-bg-white: #ffffff;
    --clr-bg-gray: #f9f9f9;
    --clr-bg-black: #000000;
    --clr-bg-footer: #f9f9f9;
    --clr-bg-dark: #191c1f;
}

:root .active-dark-mode {
    /**
      @color declaration
      */
    --clr-common-white: #ffffff;
    --clr-common-black: #000000;
    --clr-common-blue: #20d083;
    --clr-common-heading: #ffffff;
    --clr-common-body-text: #86909d;
    --clr-common-border: #2a2f36;
    --clr-common-placeholder: #b4b4b4;
    --clr-theme-1: #20d083;
    --clr-bg-white: #30373c;
    --clr-bg-gray: #252a2e;
    --clr-bg-black: #000000;
    --clr-bg-footer: #252a2e;
    --clr-bg-dark: #191c1f;
}

:root .active-light-mode {
    --clr-common-white: #ffffff;
    --clr-common-black: #000000;
    --clr-common-blue: #20d083;
    --clr-common-heading: #171717;
    --clr-common-body-text: #636363;
    --clr-common-border: #e0e2e5;
    --clr-common-placeholder: #b4b4b4;
    --clr-theme-1: #20d083;
    --clr-bg-white: #ffffff;
    --clr-bg-gray: #f9f9f9;
    --clr-bg-black: #000000;
    --clr-bg-footer: #f9f9f9;
    --clr-bg-dark: #ffffff;
}

/*--
    - Common Classes
-----------------------------------------*/
.fix {
    overflow: hidden;
}

.clear {
    clear: both;
}

.f-left {
    float: left;
}

.f-right {
    float: right;
}

.z-index-1 {
    z-index: 1;
}

.z-index-11 {
    z-index: 11;
}

.overflow-y-visible {
    overflow-x: hidden;
    overflow-y: visible;
}

.pos-rel {
    position: relative;
}

.pos-abs {
    position: absolute;
}

/*--
    - Background color
-----------------------------------------*/
.grey-bg {
    background: var(--clr-common-gray);
}

.pink-bg {
    background: var(--clr-common-black);
}

.white-bg {
    background: var(--clr-common-white);
}

.black-bg {
    background: var(--clr-common-black);
}

.footer-bg {
    background: var(--clr-bg-footer);
}

/*--
    - color
-----------------------------------------*/
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
    color: var(--clr-common-white) !important;
}

.white-color {
    color: var(--clr-common-white);
}

.theme-color {
    color: var(--clr-common-black) !important;
}

.black-color {
    color: var(--clr-common-black);
}

.body-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 99;
    left: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.body-overlay:hover {
    cursor: pointer;
}

.body-overlay.opened {
    opacity: 1;
    visibility: visible;
}

@media (max-width: 575px) {
    .progress-wrap {
        right: 15px;
        bottom: 15px;
    }
}

/* link btn */
.link-btn {
    position: relative;
    font-size: 15px;
    font-weight: 500;
    color: var(--clr-common-black);
    text-transform: uppercase;
    padding-right: 15px;
}

.link-btn i {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all ease 0.2s;
    -webkit-transition: all ease 0.2s;
    -moz-transition: all ease 0.2s;
    -ms-transition: all ease 0.2s;
    -o-transition: all ease 0.2s;
}

.link-btn i:first-child {
    left: -100%;
    visibility: hidden;
    opacity: 0;
}

.link-btn i:last-child {
    right: 0;
}

.link-btn:hover {
    color: var(--clr-common-black);
}

.link-btn:hover i:first-child {
    left: 0;
    visibility: visible;
    opacity: 1;
}

.link-btn:hover i:last-child {
    right: -100%;
    visibility: hidden;
    opacity: 0;
}

.link-btn-2 {
    position: relative;
    font-size: 14px;
    color: var(--clr-common-black);
    font-weight: 500;
    padding-right: 22px;
    display: inline-block;
    text-transform: uppercase;
    font-family: "Urbanist", sans-serif;
}

.link-btn-2 i {
    position: absolute;
    top: 45%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all ease 0.2s;
    -webkit-transition: all ease 0.2s;
    -moz-transition: all ease 0.2s;
    -ms-transition: all ease 0.2s;
    -o-transition: all ease 0.2s;
    font-size: 12px;
}

.link-btn-2 i:first-child {
    right: 10%;
    visibility: hidden;
    opacity: 0;
}

.link-btn-2 i:last-child {
    right: 0;
}

.link-btn-2:hover {
    color: var(--clr-common-black);
}

.link-btn-2:hover i:first-child {
    right: 0;
    visibility: visible;
    opacity: 1;
}

.link-btn-2:hover i:last-child {
    right: -10%;
    visibility: hidden;
    opacity: 0;
}

.link-btn-2.pink {
    color: var(--clr-common-black);
}

.link-btn-2.pink:hover {
    color: var(--clr-common-black);
}

/* pulse btn */
.pulse-btn {
    display: inline-block;
    width: 80px;
    height: 80px;
    line-height: 84px;
    text-align: center;
    background-color: var(--clr-common-white);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    color: var(--clr-common-black);
    animation: pulse 2s infinite;
}

.pulse-btn:hover {
    background-color: var(--clr-common-black);
    color: var(--clr-common-white);
}

.pulse-btn i {
    padding-left: 2px;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.fill-btn {
    font-size: 16px;
    color: var(--clr-common-white);
    font-weight: 600;
    background-image: linear-gradient(to right, #20d083, #18915d, #20d083);
    background-size: 200% 100%;
    height: 50px;
    display: inline-block;
    line-height: 50px;
    border-radius: 5px;
    padding: 0 35px;
    position: relative;
    overflow: hidden;
    transition: all linear 0.3s;
}

.fill-btn:hover {
    color: var(--clr-common-white);
    background-position: 100% 100%;
}

.fill-btn-orange {
    font-size: 16px;
    color: #171717;
    font-weight: 600;
    background: #fcbd11;
    height: 50px;
    display: inline-block;
    line-height: 50px;
    border-radius: 5px;
    padding: 0 35px;
    position: relative;
    overflow: hidden;
}

.fill-btn-orange:before {
    background-color: var(--clr-common-white);
    content: "";
    height: 150px;
    left: -75px;
    position: absolute;
    top: -35px;
    transform: rotate(35deg);
    transition: all 1600ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 40px;
    opacity: 0;
}

.fill-btn-orange:hover {
    color: #171717;
}

.fill-btn-orange:hover:before {
    left: 120%;
    transition: all 1300ms cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0.25;
}

.fill-btn-lightblue {
    font-size: 16px;
    color: var(--clr-common-white);
    font-weight: 600;
    background: #3b99fc;
    height: 50px;
    display: inline-block;
    line-height: 50px;
    border-radius: 5px;
    padding: 0 35px;
    position: relative;
    overflow: hidden;
}

.fill-btn-lightblue:before {
    background-color: var(--clr-common-white);
    content: "";
    height: 150px;
    left: -75px;
    position: absolute;
    top: -35px;
    transform: rotate(35deg);
    transition: all 1600ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 40px;
    opacity: 0;
}

.fill-btn-lightblue:hover {
    color: var(--clr-common-white);
}

.fill-btn-lightblue:hover:before {
    left: 120%;
    transition: all 1300ms cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0.25;
}

.fill-btn-lightred {
    font-size: 16px;
    color: var(--clr-common-white);
    font-weight: 600;
    background: #fd3566;
    height: 50px;
    display: inline-block;
    line-height: 50px;
    border-radius: 5px;
    padding: 0 35px;
    position: relative;
    overflow: hidden;
}

.fill-btn-lightred:before {
    background-color: var(--clr-common-white);
    content: "";
    height: 150px;
    left: -75px;
    position: absolute;
    top: -35px;
    transform: rotate(35deg);
    transition: all 1600ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 40px;
    opacity: 0;
}

.fill-btn-lightred:hover {
    color: var(--clr-common-white);
}

.fill-btn-lightred:hover:before {
    left: 120%;
    transition: all 1300ms cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0.25;
}

.border-btn {
    height: 50px;
    border: 1px solid var(--clr-theme-1);
    padding: 0 30px;
    border-radius: 5px;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    color: var(--clr-theme-1);
    line-height: 48px;
}

.border-btn:hover {
    color: var(--clr-common-heading);
    border-color: var(--clr-common-heading);
}

.border-btn i {
    margin-right: 10px;
}

.social__links ul li {
    display: inline-block;
}

.social__links ul li a {
    width: 40px;
    height: 40px;
    background: #eff1f5;
    display: inline-block;
    border: 1px solid #eff1f5;
    border-radius: 50%;
    text-align: center;
    line-height: 38px;
    color: var(--clr-common-heading);
}

.social__links ul li a:hover {
    background: #20D083FF;
    border-color: #20D083FF;
    color: var(--clr-common-white);
}

.section-main-title1 {
    font-size: 36px;
    line-height: 1.33;
    font-weight: 700;
    color: var(--clr-common-heading);
}

@media (max-width: 575px) {
    .section-main-title1 {
        font-size: 28px;
    }
}

.section-title1 p {
    font-size: 16px;
    color: var(--clr-common-body-text);
}

.basic-pagination ul li {
    display: inline-block;
    margin-right: 16px;
}

.basic-pagination ul li a,
.basic-pagination ul li span {
    position: relative;
    overflow: hidden;
    background: var(--clr-bg-white);
    color: var(--clr-common-heading);
    font-size: 16px;
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    font-weight: 700;
    border: 1px solid var(--clr-bg-white);
}

.basic-pagination ul li a:hover,
.basic-pagination ul li span.current {
    background: var(--clr-theme-1);
    color: var(--clr-common-white);
    border-color: var(--clr-theme-1);
    box-shadow: 0px 20px 30px rgba(37, 52, 103, 0.1);
}

.live-blink {
    width: 25px;
    height: 25px;
    background: transparent;
    border: 6px solid var(--clr-theme-1);
    border-radius: 50px;
    animation: live-pulse 1s infinite;
}

@keyframes live-pulse {
    0% {
        opacity: 1;
    }
    10% {
        opacity: 0.25;
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.section-title1 .live-blink {
    position: absolute;
    top: 10px;
    left: 0;
}

.nice-select {
    font-size: 15px;
    font-weight: 500;
    color: var(--clr-common-heading);
    background: var(--clr-bg-white);
    border-color: var(--clr-bg-white);
    height: 40px;
}

.nice-select::after {
    width: 8px;
    height: 8px;
    border: 4px solid #74829f;
    clip-path: polygon(100% 0, 100% 100%, 0 100%);
    right: 18px;
}

.nice-select:hover,
.nice-select:active,
.nice-select:focus {
    border-color: var(--clr-bg-white);
}

.nice-select.open {
    border-color: var(--clr-bg-white);
}

.nice-select .list {
    background-color: var(--clr-bg-white);
    box-shadow: 0 0 0 1px transparent;
    border: 1px solid var(--clr-common-border);
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
    background-color: var(--clr-common-border);
}

/*----------------------------------------
    02. header CSS
----------------------------------------*/
.bpw-nft-transparent-header {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
}

.main-menu {
    display: inline-block;
}

.main-menu ul li {
    display: inline-block;
    margin: 0px 18px;
    position: relative;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .main-menu ul li {
        margin: 0px 15px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .main-menu ul li {
        margin: 0px 13px;
    }
}

.main-menu ul li a {
    font-size: 18px;
    color: var(--clr-common-heading);
    text-transform: inherit;
    display: inline-block;
    line-height: 20px;
    font-weight: 600;
}

.main-menu ul li .sub-menu {
    background: var(--clr-bg-white) none repeat scroll 0 0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
    left: 0;
    opacity: 0;
    position: absolute;
    top: 120%;
    transition: all 0.3s ease 0s;
    visibility: hidden;
    width: 240px;
    z-index: 9;
    border-top: 4px solid var(--clr-theme-1);
    text-align: left;
    padding: 15px 0;
}

.main-menu ul li .sub-menu .sub-menu {
    left: 100%;
    top: 100%;
}

.main-menu ul li .sub-menu li {
    display: inline-flex;
    margin: 0px;
    padding: 8px 25px 8px 25px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.main-menu ul li .sub-menu li a {
    padding: 0px;
    display: block;
    color: var(--clr-common-heading);
    position: relative;
    font-weight: 400;
}

.main-menu ul li .sub-menu li a:before {
    content: "";
    width: 0;
    height: 1px;
    bottom: 0;
    position: absolute;
    left: auto;
    right: 0;
    z-index: -1;
    transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
    background: var(--clr-common-heading);
    opacity: 0;
}

.main-menu ul li .sub-menu li:hover > a {
    color: var(--clr-theme-1);
    padding-left: 5px;
}

.main-menu ul li .sub-menu li:hover > a:before {
    width: 100%;
    left: 0;
    right: auto;
}

.main-menu ul li:hover > a {
    color: var(--clr-theme-1);
}

.main-menu ul li:hover > .sub-menu {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

.main-menu ul li:hover > .sub-menu li:hover > .sub-menu {
    top: 0;
}

.main-menu ul li:hover.menu-item-has-children::after {
    color: var(--clr-theme-1);
    opacity: 1;
    visibility: visible;
}

.main-menu ul li.menu-item-has-children {
    position: relative;
}

.main-menu ul li.menu-item-has-children:after {
    position: static;
    content: "";
    font-size: 12px;
    color: var(--clr-common-heading);
    opacity: 1;
    visibility: visible;
    font-family: "Font Awesome 5 Pro";
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    margin-left: 2px;
}

.action-item {
    display: inline-block;
}

.action-item a {
    display: inline-block;
    position: relative;
}

.action-item a i {
    font-size: 20px;
    color: var(--clr-common-heading);
}

.action-item .action-item-number {
    width: 18px;
    height: 18px;
    background: var(--clr-theme-1);
    display: inline-block;
    text-align: center;
    line-height: 18px;
    color: var(--clr-common-white);
    border-radius: 50%;
    position: absolute;
    right: -10px;
    top: -8px;
    font-size: 12px;
}

.container.header-container {
    max-width: 1650px;
}

.header-top {
    padding: 10px 0;
    border-bottom: 1px solid var(--clr-common-border);
    overflow-x: clip;
}

.offer-text {
    display: flex;
    align-items: center;
    gap: 20px;
}

.offer-text p {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0;
}

.art-auction-ends.offer-time-end {
    font-size: 15px;
    background: #5666ff;
    height: 30px;
    line-height: 30px;
    color: var(--clr-common-white);
    padding: 0 10px;
    border-radius: 5px;
}

.header-top-right {
    display: flex;
    gap: 40px;
    justify-content: end;
}

.header-top-right .nice-select {
    padding-left: 0;
    padding-right: 17px;
    width: inherit;
    height: 30px;
    line-height: 30px;
    color: var(--clr-common-body-text);
    font-size: 15px;
    background: none;
    border: none;
    margin-bottom: 0;
    position: relative;
    min-width: 46px;
}

.header-top-right .nice-select::after {
    right: 0;
    border-width: 2px;
    top: 14px;
}

.header-top-right .nice-select:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 1px;
    background: #dde0e4;
    top: 5px;
    left: -20px;
}

.header-top-right .nice-select.language-select::before {
    display: none;
}


.header-main1 {
    border-bottom: 1px solid var(--clr-common-border);
    overflow-x: clip;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .header1-logo img {
        max-width: 160px;
    }
}

.header-main-right {
    display: flex;
    align-items: center;
    justify-content: end;
}

.main-menu1 {
    margin-right: 95px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px) {
    .main-menu1 {
        margin-right: 30px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .main-menu1 {
        margin-right: 50px;
    }
}

.profile-item-header .profile-img {
    position: relative;
    cursor: pointer;
}

.profile-item-header .profile-img:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: transparent;
    z-index: 1;
    border-radius: 50%;
}

.profile-item-header .profile-img img {
    width: 45px;
    height: 45px;
    border: 0;
    cursor: pointer;
}

.profile-item-header .profile-verification {
    width: 18px;
    height: 18px;
    border-width: 2px;
    line-height: 16px;
    font-size: 7px;
    right: 0;
    bottom: 0;
}

.into-sidebar .profile-action ul {
    left: 110%;
    top: 0;
    background: #1c2434;
}

.into-sidebar .profile-action ul li a {
    color: #9ba3af;
}


.filter-search-input.header-search input {
    border: 1px solid #2d3136;
    border-radius: 5px;
    background: none;
    padding: 0 50px 0 20px;
    color: var(--clr-common-heading);
}

.filter-search-input.header-search button {
    top: 9px;
    color: #8b8d8f;
}

.filter-search-input.header-search button::before {
    position: absolute;
    content: "";
    width: 1px;
    height: 20px;
    background: var(--clr-common-border);
    top: 50%;
    transform: translateY(-50%);
    left: -18px;
}

.header-btn .fill-btn {
    height: 45px;
    line-height: 45px;
    display: inline-flex;
}

.action-list-header1 .action-item {
    margin-right: 25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .action-list-header1 .action-item {
        margin-right: 15px;
    }
}

.action-list-header1 .action-item:last-child {
    margin-right: 0;
}

.sticky {
    position: fixed;
    top: 0;
    background: var(--clr-bg-white);
    z-index: 800;
    right: 0;
    left: 0;
    width: 100%;
    transition: 0.4s;
    box-shadow: 0 0 60px 0 rgba(53, 57, 69, 0.15);
    animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    border: none;
}

/* bar-icon style */
.bar-icon {
    width: 20px;
    height: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}

.bar-icon span {
    width: 100%;
    height: 2px;
    background: var(--clr-common-heading);
    display: inline-block;
}

.bar-icon span:nth-child(2) {
    margin-left: 40%;
    transition: 0.3s;
}

.bar-icon:hover span:nth-child(2) {
    margin-left: 0;
}

.bar-icon.left-bar-icon span:nth-child(2) {
    margin-left: 0;
    transition: 0.3s;
    width: 60%;
}

.bar-icon.left-bar-icon:hover span:nth-child(2) {
    width: 100%;
}

.offcanvas-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    background: #2b2e32;
    z-index: 900;
    top: 0;
    opacity: 0;
    visibility: hidden;
}

.offcanvas-overlay.overlay-open {
    opacity: 0.3;
    visibility: visible;
}

.offcanvas-overlay-white {
    position: fixed;
    height: 100%;
    width: 100%;
    background: var(--clr-common-white);
    z-index: 900;
    top: 0;
    opacity: 0;
    visibility: hidden;
}

.offcanvas-overlay-white.overlay-open {
    opacity: 0;
    visibility: visible;
}

.side-info-close {
    background: none;
    border: 0;
    color: var(--clr-common-white);
    font-size: 20px;
    padding: 0;
    -webkit-transition: all 0.5s ease-out 0s;
    -moz-transition: all 0.5s ease-out 0s;
    -ms-transition: all 0.5s ease-out 0s;
    -o-transition: all 0.5s ease-out 0s;
    transition: all 0.5s ease-out 0s;
}

.side-info {
    background: #000;
    height: 100%;
    position: fixed;
    z-index: 99999;
    right: -100%;
    top: 0;
    width: 365px;
    padding: 45px 35px 45px 45px;
    transition: 0.6s;
    overflow-y: scroll;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .side-info {
        width: 300px;
        padding: 15px 25px;
    }
}

.side-info.info-open {
    right: 0;
}

.offset-widget .filter-search-input input {
    background: #000;
    border-radius: 10px;
    border: 1px solid var(--clr-bg-dark);
    color: #9ba3af;
}

.offset-widget p {
    color: #9ba3af;
}

.offset-profile-action {
    display: flex;
    align-items: center;
}

.offset-widget .action-item a i {
    color: var(--clr-common-white);
}

/* mean menu */
div.mean-container .mean-bar {
    background: transparent;
}

div.mean-container .mean-nav {
    background: none;
    margin-bottom: 40px;
    overflow: hidden;
}

div.mean-container .mean-nav > ul {
    display: block !important;
}

div.mean-container .mean-nav ul li a {
    width: 100%;
    color: var(--clr-common-white);
    border-top: 1px solid #191c1f;
    font-size: 16px;
    text-transform: none;
    opacity: 1;
}

div.mean-container .mean-nav ul li a:hover {
    color: var(--clr-theme-1);
}

div.mean-container .mean-nav ul li a i {
    display: none;
}

div.mean-container .mean-nav ul li a.mean-expand {
    height: 28px;
    border: none;
}

div.mean-container .mean-nav ul li a.mean-expand::before {
    position: absolute;
    content: "+";
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

div.mean-container .mean-nav ul li a.mean-expand.mean-clicked::before {
    content: "-";
}

div.mean-container a.meanmenu-reveal {
    display: none !important;
}

.header-main2 {
    background: #eff1f5;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 850;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .header-main2 {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.header-main2-content {
    background: var(--clr-bg-white);
    padding: 20px 30px;
    border-radius: 0 0 10px 10px;
    margin-bottom: 40px;
}

.header-main2-content .header-main-left {
    display: flex;
    align-items: center;
}

.header-main2-content .header-main-right {
    display: flex;
    align-items: center;
}

.header-main2-content .filter-search-input.header-search {
    width: 600px;
}

.header-main2-content .filter-search-input.header-search input {
    height: 50px;
    border: none;
    background: #eff1f5;
}

.product-filter-btn svg * {
    fill: var(--clr-common-white);
}

.menu2-side-bar {
    width: 275px;
    left: 0;
    background: var(--clr-bg-white);
    padding: 27px 27px;
    border-right: 1px solid var(--clr-common-border);
    right: auto;
    top: 0;
}

.menu2-sidebar-widget-title {
    font-size: 18px;
    font-weight: 600;
    color: var(--clr-common-heading);
}

.sidebar-creators-list .creator-single-short {
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--clr-common-border);
}

.sidebar-creators-list .creator-single-short:last-child {
    padding-bottom: 0;
    border: none;
}

.sidebar-creators-list .artist .profile-img img {
    width: 45px;
    border: none;
}

.sidebar-creators-list .artist .profile-img {
    margin-right: 16px;
}

.sidebar-creators-list .artist .profile-verification {
    width: 15px;
    height: 15px;
    font-size: 7px;
}

.sidebar-creators-list .artist {
    margin-bottom: 0;
}

.sidebar-creators-list .artist-name {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
    color: var(--clr-common-heading);
}

.sidebar-creators-list .artist-name a:hover {
    color: var(--clr-theme-1);
}

.sidebar-creators-list .creator-name-id .artist-id {
    font-size: 14px;
    color: var(--clr-theme-1);
}

.menu2-sidebar-widget .process-icon {
    height: 79px;
    margin-bottom: 13px;
}

.menu2-sidebar-widget .process-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
}

.menu2-sidebar-widget .fill-btn {
    font-size: 12px;
    font-weight: 600;
    height: 35px;
    line-height: 35px;
    padding: 0 18px;
}

.menu2-sidebar-widget .work-process-btn {
    line-height: 1;
}

.menu2-sidebar-widget .work-process-single {
    padding: 27px 27px;
    border-radius: 0;
    margin-left: -27px;
    margin-right: -27px;
    margin-bottom: -27px;
    background: #f9f9f9;
}

.menu2-mobile-menu {
    border-bottom: 1px solid var(--clr-common-border);
    margin-bottom: 30px;
}

.menu2-mobile-menu.mean-container .mean-nav ul li a {
    font-size: 18px;
    font-weight: 600;
    color: var(--clr-common-heading);
}

.menu2-mobile-menu.mean-container .mean-nav ul li a:hover {
    color: var(--clr-theme-1);
}

.menu2-mobile-menu.mean-container .mean-nav ul li a.mean-expand {
    background: var(--clr-bg-white);
}

.menu2-mobile-menu.mean-container .mean-nav ul li a.mean-expand::before {
    position: absolute;
    content: "+";
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: "";
    font-family: "Font Awesome 5 Pro";
    font-size: 15px;
    font-weight: 400;
}

.menu2-mobile-menu.mean-container
.mean-nav
ul
li
a.mean-expand.mean-clicked::before {
    content: "";
    font-family: "Font Awesome 5 Pro";
}

.menu2-mobile-menu.mean-container .mean-nav ul li a.mean-expand::after {
    position: absolute;
    content: "";
    width: 220px;
    height: 100%;
    right: 0;
    top: 0;
}

.menu2-mobile-menu.mean-container .mean-nav ul li:hover > a {
    color: var(--clr-theme-1);
}

.menu2-mobile-menu.mean-container .mean-nav ul li a {
    border-top: 0;
}

.menu2-mobile-menu.mean-container .mean-nav {
    margin-bottom: 20px;
}

.sidebar-category-filter {
    width: 275px;
    right: 0;
    background: var(--clr-bg-white);
    padding: 27px 27px;
    border-left: 1px solid var(--clr-common-border);
    left: auto;
    top: 0;
}

.sidebar-category-filter .category-item {
    display: block;
}

.sidebar-category-filter .category-item a {
    display: inline-flex;
    align-items: center;
    padding: 0 0px;
    font-size: 16px;
    font-weight: 600;
    color: var(--clr-common-body-text);
    width: 100%;
    border-radius: 0;
    background: none;
    height: 30px;
    border: none;
    line-height: 30px;
}

.sidebar-category-filter .filter-widget-content ul li {
    margin-bottom: 7px;
}

.sidebar-category-filter .filter-widget-title {
    font-size: 18px;
    font-weight: 600;
    color: var(--clr-common-heading);
    cursor: pointer;
    position: relative;
}

.sidebar-category-filter .filter-widget-title::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: transparent;
    z-index: 1;
}

.sidebar-category-filter .filter-widget-content {
    display: flex;
    flex-direction: column;
    gap: 25px;
    position: relative;
}

.sidebar-category-filter .filter-widget-content::after {
    position: absolute;
    top: 3px;
    right: 0;
    content: "";
    font-family: "Font Awesome 5 Pro";
    color: var(--clr-common-heading);
    cursor: pointer;
    transform: rotate(-180deg);
    line-height: 1;
}

.sidebar-category-filter .filter-widget-content.content-hidden::after {
    transform: rotate(0deg);
}

.sidebar-category-filter .filter-widget-content.content-hidden ul {
    display: none;
}

.sidebar-category-filter .filter-widget-content .bpw-nft-check-label {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
}

.sidebar-category-filter .filter-widget-content input[type="checkbox"] {
    border: 1px solid #86909d;
    border-radius: 2px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 15px;
    height: 15px;
    cursor: pointer;
    position: relative;
    top: 2px;
    font-size: 15px;
    margin-right: 8px;
}

.sidebar-category-filter .filter-widget-content input[type="checkbox"]:checked {
    border: none;
    background-color: #2467ec;
    color: #ffffff;
}

.sidebar-category-filter
.filter-widget-content
input[type="checkbox"]:checked::before {
    position: absolute;
    content: "";
    font-family: "Font Awesome 5 Pro";
    line-height: 15px;
    top: 0;
    left: 3px;
    font-size: 10px;
}

@media (min-width: 1400px) {
    .c-container-1 {
        width: calc(100% - 583px);
    }
}

.menu2-side-bar-wrapper {
    width: 275px;
    position: fixed;
    top: 0;
    left: -300px;
    transition: 0.3s;
    z-index: 999;
    height: 100vh;
    min-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
}

@media (min-width: 1400px) {
    .menu2-side-bar-wrapper {
        left: 0;
    }
}

.menu2-side-bar-wrapper.open {
    left: 0;
}

.sidebar-category-filter-wrapper {
    width: 275px;
    position: fixed;
    top: 0;
    right: -300px;
    transition: 0.3s;
    z-index: 999;
    height: 100vh;
    min-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
}

@media (min-width: 1400px) {
    .sidebar-category-filter-wrapper {
        right: 0;
    }
}

.sidebar-category-filter-wrapper.open {
    right: 0;
}

.body-has-sidebar {
    position: relative;
    overflow-x: clip;
}

.product-filter-btn {
    font-size: 22px;
    color: var(--clr-common-heading);
    line-height: 0;
    cursor: pointer;
}

.home3-mode-switch.mode-switch-wrapper {
    position: static;
    transform: none;
}

.home3-mode-switch .label .ball {
    display: none;
}

.home3-mode-switch .label {
    width: 45px;
    height: 45px;
    background: transparent;
    border-radius: 50%;
    text-align: center;
    line-height: 45px;
    border: 1px solid var(--clr-common-border);
    transform: scale(1);
    font-size: 20px;
    padding: 0;
    justify-content: center;
}

.home3-mode-switch .label i {
    height: 45px;
    width: 45px;
    line-height: 45px;
}

/*----------------------------------------
    03. banner CSS
----------------------------------------*/
.banner-900 {
    min-height: 900px;
    position: relative;
}

.banner-670 {
    min-height: 670px;
    position: relative;
}

.banner-460 {
    min-height: 460px;
    position: relative;
}

.banner-bg,
.banner-bg-light {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: -1;
}

.b1-shape1 {
    position: absolute;
    left: 2.5%;
    top: 29%;
    filter: blur(2px);
}

.active-dark-mode .banner-bg-light {
    display: none;
}

.active-light-mode .banner-bg {
    display: none;
}

.single-banner-1 {
    padding-top: 130px;
}

.search-form {
    position: relative;
    width: 100%;
    max-width: 610px;
}

.search-form::before {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    color: #b1b1b1;
    font-family: "Font Awesome 5 Pro", serif;
    font-weight: 300;
    left: 19px;
}

.search-form input {
    width: 100%;
    height: 70px;
    line-height: 60px;
    padding: 0 135px 0 45px;
    color: var(--clr-common-heading);
    border-radius: 5px;
    background: var(--clr-common-white);
    border: none;
}

.search-form input::placeholder {
    font-size: 14px;
    color: #b1b1b1;
}

.search-form input::focus {
    border: 0;
}

.search-form button {
    font-size: 16px;
    font-weight: 700;
    text-transform: inherit;
    line-height: 50px;
    text-align: center;
    height: 50px;
    background: var(--clr-theme-1);
    color: var(--clr-common-white);
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    bottom: 0;
    border-radius: 5px;
    overflow: hidden;
    padding: 0 30px;
}

.search-form button i {
    color: var(--clr-common-white);
    margin-left: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .banner-btn.mb-105 {
        margin-bottom: 60px;
    }
}

.banner-content h1 {
    font-size: 65px;
    line-height: 1.2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner-content h1 {
        font-size: 60px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-content h1 {
        font-size: 44px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .banner-content h1 {
        font-size: 44px;
    }
}

@media (max-width: 575px) {
    .banner-content h1 {
        font-size: 34px;
    }
}

.banner-content h1 span {
    font-weight: 800;
    color: var(--clr-theme-1);
}

.banner-content p {
    font-size: 20px;
}

.banner-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}

.bpw-nft-banner-video {
    display: flex;
    align-items: center;
    gap: 15px;
}

.bpw-nft-banner-video a {
    height: 50px;
    width: 50px;
    line-height: 48px;
    text-align: center;
    border: 1px solid var(--clr-common-body-text);
    display: inline-block;
    color: var(--clr-common-heading);
    font-size: 14px;
    border-radius: 50%;
}

.bpw-nft-banner-video span {
    font-size: 16px;
    font-weight: 500;
    font-family: "Urbanist", sans-serif;
    display: inline-block;
    color: var(--clr-common-heading);
}

.bpw-nft-banner-counter {
    display: flex;
    gap: 70px;
}

@media (max-width: 575px) {
    .bpw-nft-banner-counter {
        gap: 50px;
    }
}

.bpw-nft-banner-counter .art-meta-notice {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 15px;
}

@media (max-width: 575px) {
    .bpw-nft-banner-counter .art-meta-notice {
        font-size: 30px;
    }
}

.bpw-nft-banner-counter .art-meta-type {
    font-size: 16px;
    font-weight: 500;
    text-transform: inherit;
    margin-bottom: 0;
}

.banner-content1 {
    max-width: 610px;
    position: relative;
    z-index: 5;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner-content1 {
        max-width: 530px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner-content1 {
        margin-bottom: 60px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .banner-content1 {
        max-width: 430px;
        padding-top: 0;
        padding-bottom: 50px;
    }
}

@media (max-width: 575px) {
    .banner-content1 {
        padding-top: 0;
        padding-bottom: 90px;
    }
}

.bpw-nft-banner-img {
    height: 650px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
    .bpw-nft-banner-img {
        max-width: 520px;
    }
}

@media (max-width: 575px) {
    .bpw-nft-banner-img {
        max-width: 350px;
        margin: 0 auto;
        height: 440px;
    }
}

.bpw-nft-banner-img .bpw-nft-banner-img-1 {
    position: absolute;
    border-radius: 13px;
    top: 0px;
    right: 91px;
    z-index: 1;
}

@media (max-width: 575px) {
    .bpw-nft-banner-img .bpw-nft-banner-img-1 img {
        height: 150px;
    }
}

.bpw-nft-banner-img .bpw-nft-banner-img-1 img {
    border-radius: 13px;
}

.bpw-nft-banner-img .bpw-nft-banner-img-2 {
    position: absolute;
    border-radius: 13px;
    top: 160px;
    left: 0px;
}

@media (max-width: 575px) {
    .bpw-nft-banner-img .bpw-nft-banner-img-2 {
        top: 80px;
    }

    .bpw-nft-banner-img .bpw-nft-banner-img-2 img {
        height: 120px;
    }
}

.bpw-nft-banner-img .bpw-nft-banner-img-2 img {
    border-radius: 13px;
}

.bpw-nft-banner-img .bpw-nft-banner-img-3 {
    position: absolute;
    border-radius: 13px;
    top: 80px;
    right: -200px;
}

@media (max-width: 575px) {
    .bpw-nft-banner-img .bpw-nft-banner-img-3 {
        right: 0px;
    }

    .bpw-nft-banner-img .bpw-nft-banner-img-3 img {
        height: 200px;
    }
}

.bpw-nft-banner-img .bpw-nft-banner-img-3 img {
    border-radius: 13px;
}

.bpw-nft-banner-img .bpw-nft-banner-img-4 {
    position: absolute;
    border-radius: 13px;
    bottom: 91px;
    left: 61px;
}

@media (max-width: 575px) {
    .bpw-nft-banner-img .bpw-nft-banner-img-4 {
        left: 10px;
    }

    .bpw-nft-banner-img .bpw-nft-banner-img-4 img {
        height: 180px;
    }
}

.bpw-nft-banner-img .bpw-nft-banner-img-4 img {
    border-radius: 13px;
}

.bpw-nft-banner-img .bpw-nft-banner-client-wrapper {
    position: absolute;
    left: -30px;
    bottom: 0px;
    z-index: 2;
}

@media (max-width: 575px) {
    .bpw-nft-banner-img .bpw-nft-banner-client-wrapper {
        left: 0px;
    }
}

.bpw-nft-banner-img .bpw-nft-banner-client {
    padding: 30px;
    border-radius: 10px;
    max-width: 300px;
    height: 185px;
    background: transparent;
    border: 1px solid rgba(162, 175, 200, 0.31);
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(12px);
    --webkit-backdrop-filter: blur(12px);
    background-color: rgba(255, 255, 255, 0.05);
    /* (plus shape's fill blended on top as a separate layer with 10% opacity) */
}

.bpw-nft-banner-img .bpw-nft-banner-client img {
    position: static;
    height: 100%;
    width: 100%;
}

.bpw-nft-banner-img .bpw-nft-banner-client a {
    height: 40px;
    width: 40px;
    border: 2px solid var(--clr-common-white);
    border-radius: 50%;
    object-fit: cover;
    display: inline-block;
    overflow: hidden;
}

.bpw-nft-banner-img .bpw-nft-banner-client a:not(:first-child) {
    margin-left: -15px;
}

.bpw-nft-banner-img .bpw-nft-banner-client .q-meta-viewed-members {
    display: inline-flex;
}

.bpw-nft-banner-img .bpw-nft-banner-client .q-meta-views {
    font-size: 14px;
    color: var(--clr-common-heading);
    display: block;
}

.bpw-nft-banner-img .bpw-nft-banner-client .q-meta-type {
    font-size: 16px;
    color: var(--clr-common-heading);
    font-weight: 700;
}

.bpw-nft-banner-img .bpw-nft-banner-client .q-meta-item {
    gap: 10px;
    margin-bottom: 13px;
}

.bpw-nft-banner-img .bpw-nft-banner-client p {
    font-size: 14px;
    line-height: 24px;
    color: var(--clr-common-heading);
}

@-moz-document url-prefix() {
    .active-dark-mode .bpw-nft-banner-client {
        background: #3841547a;
    }

    .active-light-mode .bpw-nft-banner-client {
        background: #e7ebf47a;
        border: 1px solid rgba(147, 161, 188, 0.14);
    }
}

@keyframes circle {
    from {
        -webkit-transform: rotateZ(0deg);
        transform: rotateZ(0deg);
    }
    to {
        -webkit-transform: rotateZ(360deg);
        transform: rotateZ(360deg);
    }
}

.banner-content2 {
    text-align: center;
}

.banner-content2 .banner-btn {
    display: inherit;
}

.banner-content2.banner-content p {
    max-width: 630px;
    margin-left: auto;
    margin-right: auto;
}

.banner2-container {
    overflow-x: clip;
    overflow-y: visible;
}

.single-banner-3 {
    border-radius: 10px;
    overflow: hidden;
}

.single-banner-3 .banner-bg img,
.single-banner-3 .banner-bg-light img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.banner-content3 h1 {
    font-size: 40px;
    font-weight: 700;
    max-width: 400px;
    line-height: 1.25;
    color: var(--clr-common-white);
}

@media (max-width: 575px) {
    .banner-content3 h1 {
        font-size: 34px;
    }
}

.banner-content3 p {
    font-size: 16px;
    color: var(--clr-common-white);
    max-width: 350px;
}

.banner-content3 {
    padding: 0 40px;
}

@media (max-width: 575px) {
    .banner-content3 {
        padding: 0 25px;
    }
}

@media (min-width: 1600px) and (max-width: 1800px) {
    .banner-3-column {
        width: 60%;
    }
}

@media (min-width: 1400px) and (max-width: 1599px) {
    .banner-3-column {
        width: 100%;
    }
}

@media (min-width: 1600px) and (max-width: 1800px) {
    .sidebar-art-list-column {
        width: 40%;
    }
}

@media (min-width: 1400px) and (max-width: 1599px) {
    .sidebar-art-list-column {
        width: 100%;
    }
}

/*----------------------------------------
    04. about CSS
----------------------------------------*/
.error-404-content h4 {
    font-size: 30px;
    margin-bottom: 13px;
}

.error-404-img img {
    width: 600px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .error-404-img img {
        width: 450px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .error-404-img img {
        width: 400px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .error-404-img img {
        width: 350px;
    }
}

@media (max-width: 575px) {
    .error-404-img img {
        width: 100%;
    }
}

@media only screen and (min-width: 450px) and (max-width: 575px) {
    .error-404-img img {
        width: 350px;
    }
}

.art-category-select {
    width: 100%;
    height: 60px;
    line-height: 60px;
    border: 1px solid var(--clr-bg-white);
    border-radius: 5px;
    color: var(--clr-common-heading);
    background: var(--clr-bg-white);
    font-size: 16px;
    margin-bottom: 30px;
}

.sale-category-box-title {
    font-size: 16px;
    font-weight: 700;
    color: var(--clr-common-heading);
    margin-bottom: 13px;
}

.sale-category-box-title i {
    margin-right: 10px;
}

.sale-categoty-box {
    border: 1px solid var(--clr-bg-white);
    border-radius: 5px;
    padding: 11px 20px;
    margin-bottom: 30px;
    background: var(--clr-bg-white);
}

.sale-categoty-box p {
    font-size: 15px;
    margin-bottom: 0;
}

.currency-select {
    width: 100%;
    height: 60px;
    line-height: 60px;
    border: 1px solid var(--clr-bg-white);
    border-radius: 5px;
    color: var(--clr-common-heading);
    background: var(--clr-bg-white);
    font-size: 16px;
    margin-bottom: 30px;
}

.royalty-select {
    width: 100%;
    height: 60px;
    line-height: 60px;
    border: 1px solid var(--clr-bg-white);
    border-radius: 5px;
    color: var(--clr-common-heading);
    background: var(--clr-bg-white);
    font-size: 16px;
    margin-bottom: 30px;
}

.upload-btn {
    display: flex;
    gap: 30px;
    margin-bottom: 30px;
    flex-wrap: wrap;
}

.upload-btn button {
    min-width: 150px;
}

.browse-file-text {
    font-size: 20px;
    font-weight: 600;
    color: var(--clr-common-heading);
    margin-bottom: 8px;
}

.browse-file-icon {
    font-size: 72px;
    color: #dde0f9;
    margin-bottom: 20px;
}

.browse-file-note {
    font-size: 14px;
    font-weight: 600;
}

.browse-file-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed #20d083;
    border-radius: 10px;
    padding: 40px 30px;
    text-align: center;
    margin-bottom: 30px;
}

.browse-file-btn {
    margin-top: 15px;
    margin-bottom: 18px;
}

.uploaded-file-wrapper {
    border: 1px solid var(--clr-bg-white);
    background: var(--clr-bg-white);
    border-radius: 10px;
    padding: 35px 35px;
}

@media (max-width: 575px) {
    .uploaded-file-wrapper {
        padding: 35px 15px;
    }
}

.uploaded-file-text {
    font-size: 16px;
    font-weight: 700;
    color: var(--clr-common-heading);
    margin-bottom: 10px;
}

.uploaded-file {
    margin-bottom: 20px;
}

.uploaded-file img {
    width: 100%;
}

.uploaded-file-btn {
    text-align: center;
}

.uploaded-file-btn .text-btn {
    font-size: 16px;
    color: var(--clr-common-heading);
    border-bottom: 1px solid var(--clr-common-heading);
}

.about-info-title {
    font-size: 24px;
    margin-bottom: 23px;
}

.about-info-wrapper p {
    margin-bottom: 28px;
}

.about-points-box {
    border: 1px solid var(--clr-bg-white);
    border-radius: 10px;
    background: var(--clr-bg-white);
    padding: 28px 45px;
}

.about-points-box li {
    list-style: disc;
    color: var(--clr-theme-1);
    margin-bottom: 2px;
}

.about-points-box li a {
    color: var(--clr-theme-1);
    text-decoration: underline;
}

.about-points-box li:last-child {
    margin-bottom: 0;
}

.about-info-subtitle {
    font-size: 18px;
    margin-bottom: 15px;
}

.about-points-list li {
    list-style: decimal-leading-zero;
    margin-left: 27px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.about-points-list li:last-child {
    margin-bottom: 0;
}

.sidebar-widget-single {
    border: 1px solid var(--clr-bg-gray);
    background: var(--clr-bg-gray);
    border-radius: 10px;
    padding: 30px 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 575px) {
    .sidebar-widget-single {
        padding: 30px 15px;
    }
}

.sidebar-nav-link {
    width: 100%;
    height: 60px;
    border: 1px solid var(--clr-bg-white);
    display: block;
    border-radius: 5px;
    font-weight: 600;
    color: var(--clr-common-heading);
    line-height: 58px;
    padding: 0 30px;
    position: relative;
    background: var(--clr-bg-white);
    text-align: left;
    margin-bottom: 10px;
}

.sidebar-nav-link:hover {
    background: #20d083;
    color: var(--clr-common-white);
    box-shadow: 0px 30px 40px rgba(28, 52, 94, 0.1);
    border-color: #20d083;
}

.sidebar-nav-link:hover i {
    color: var(--clr-common-white);
}

.sidebar-nav-link:hover .inner-item-number {
    color: var(--clr-common-white);
}

.sidebar-nav-link .inner-item-number {
    position: absolute;
    font-weight: 600;
    color: var(--clr-common-heading);
    right: 30px;
    top: 0;
    transition: 0.3s;
}

.sidebar-tab-nav .nav-link {
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    border: none;
    background: none;
}

.sidebar-tab-nav .nav-link.active {
    background: none;
}

.sidebar-tab-nav .nav-link.active .sidebar-nav-link {
    background: #20d083;
    color: var(--clr-common-white);
    box-shadow: 0px 30px 40px rgba(28, 52, 94, 0.1);
    border-color: #20d083;
}

.sidebar-tab-nav .nav-link.active .sidebar-nav-link i {
    color: var(--clr-common-white);
}

.sidebar-tab-nav .nav-link.active .sidebar-nav-link .inner-item-number {
    color: var(--clr-common-white);
}

.sidebar-tab-nav .nav-link:last-child {
    margin-bottom: 0;
}

.sidebar-tab-nav .nav-tabs {
    border-bottom: 0;
}

.sidebar-nav-link i {
    font-size: 20px;
    color: var(--clr-theme-1);
    margin-right: 20px;
    transition: 0.3s;
}

.category-list li {
    margin-bottom: 20px;
}

.category-list li a {
    width: 100%;
    height: 60px;
    border: 1px solid var(--clr-common-border);
    display: inline-block;
    border-radius: 5px;
    font-weight: 600;
    color: var(--clr-common-heading);
    line-height: 58px;
    padding: 0 30px;
    position: relative;
    background: var(--clr-common-white);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 575px) {
    .category-list li a {
        padding: 0 20px;
    }
}

.category-list li a i {
    font-size: 20px;
    color: var(--clr-theme-1);
    margin-right: 20px;
    transition: 0.3s;
}

.category-list li a:hover {
    background: var(--clr-theme-1);
    color: var(--clr-common-white);
    box-shadow: 0px 30px 40px rgba(28, 52, 94, 0.1);
    border-color: var(--clr-theme-1);
}

.category-list li a:hover i {
    color: var(--clr-common-white);
}

.category-list li a:hover .inner-item-number {
    color: var(--clr-common-white);
}

.category-list li:last-child {
    margin-bottom: 0;
}

.category-list .inner-item-number {
    position: absolute;
    font-weight: 600;
    color: var(--clr-common-heading);
    right: 30px;
    top: 0;
    transition: 0.3s;
}

.sidebar-widget-title {
    font-size: 20px;
    margin-bottom: 35px;
}

.accordion-general .accordion-item {
    margin-bottom: 10px;
    border: none;
    border-radius: 10px;
    overflow: hidden;
    background: var(--clr-bg-white);
}

.accordion-general .accordion-item:last-child {
    margin-bottom: 0;
}

.accordion-general .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.accordion-general .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.accordion-general .accordion-body {
    font-size: 18px;
    line-height: 30px;
    padding: 20px 30px 30px;
    background: var(--clr-bg-white);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 575px) {
    .accordion-general .accordion-body {
        padding: 20px 15px 30px;
    }
}

.accordion-general .accordion-button {
    border: none;
    background: var(--clr-bg-white);
    border-bottom: 1px solid var(--clr-bg-white);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 20px;
    color: var(--clr-common-heading);
    font-weight: 700;
    font-family: "Urbanist", sans-serif;
    padding: 28px 20px 28px 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 575px) {
    .accordion-general .accordion-button {
        padding: 20px 15px 20px 45px;
        font-size: 18px;
    }
}

.accordion-general .accordion-button::after {
    content: "";
    font-family: "Font Awesome 5 Pro";
    background-image: none;
    position: absolute;
    left: 30px;
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 575px) {
    .accordion-general .accordion-button::after {
        left: 15px;
    }
}

.accordion-general .accordion-button:focus {
    box-shadow: none;
}

.accordion-general .accordion-collapse {
    border: none;
}

.accordion-general .accordion-collapse.show {
    border-top: 1px solid var(--clr-common-border);
}

.accordion-general .accordion-button.collapsed {
    border-bottom: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.accordion-general .accordion-button.collapsed::after {
    content: "";
}

.accordion-general .accordion-button.collapsed:focus {
    border-color: var(--clr-common-white);
}

.sidebar-auction-active .art-item-wraper {
    padding: 0;
    background: var(--clr-bg-gray);
}

.sidebar-auction-active .art-item-content {
    padding: 20px 0px 0px;
}

.sidebar-auction-active .art-3dots-menu {
    right: 0;
}

/*----------------------------------------
    05. page-title CSS
----------------------------------------*/
.page-title-area {
    border-top: 0;
    border-bottom: 1px solid var(--clr-common-border);
    padding: 58px 0;
}

.breadcrumb-title {
    font-size: 36px;
}

@media (max-width: 575px) {
    .breadcrumb-title {
        font-size: 30px;
    }
}

@media only screen and (min-width: 450px) and (max-width: 575px) {
    .breadcrumb-title {
        font-size: 36px;
    }
}

.page-title .trail-items .trail-item {
    display: inline-block;
}

.page-title .trail-items .trail-item a {
    position: relative;
    color: #20d083 !important;
    font-size: 15px;
    font-weight: 700;
}

.page-title .trail-items .trail-item span {
    font-size: 15px;
    font-weight: 700;
    color: var(--clr-common-body-text);
}

/*----------------------------------------
    06. profile CSS
----------------------------------------*/
.profile-img {
    display: inline-block;
    line-height: 1;
}

.profile-img img {
    width: 90px;
    border: 5px solid var(--clr-bg-white);
    border-radius: 50%;
    background: var(--clr-bg-white);
}

.profile-verification {
    width: 22px;
    height: 22px;
    border: 3px solid var(--clr-bg-white);
    background: #ffc107;
    text-align: center;
    line-height: 18px;
    border-radius: 50%;
    color: var(--clr-common-white);
    font-size: 8px;
    position: absolute;
    right: 5px;
    bottom: 0;
}

.profile-verification.verified {
    background: var(--clr-theme-1);
}

.profile-action {
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
}

.profile-action ul {
    background: var(--clr-bg-white);
    padding: 10px 15px;
    min-width: 120px;
    border-radius: 5px;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 99999;
    box-shadow: 0px 30px 20px rgba(37, 52, 103, 0.11);
}

.profile-action ul li i {
    margin-right: 10px;
    font-size: 12px;
}

.profile-action ul li a {
    font-size: 16px;
    font-weight: 500;
    display: block;
    padding: 5px 0;
}

.profile-action ul li a:hover {
    color: var(--clr-theme-1);
}

.show-element .profile-action {
    opacity: 1;
    visibility: visible;
}

/*----------------------------------------
    07. art CSS
----------------------------------------*/
.art-item-single {
    transition: 0.3s;
    border-radius: 15px;
}

.art-item-single:hover .art-3dots-menu {
    opacity: 1;
    visibility: visible;
}

.art-item-single:hover .art-action {
    opacity: 1;
    visibility: visible;
}

.art-item-single:hover .art-item-img a img {
    transform: scale(1.05);
}

.art-item-single:hover .place-bid {
    opacity: 1;
    visibility: visible;
}

.art-item-wraper {
    background: var(--clr-bg-white);
    border-radius: 10px;
    padding: 10px;
    border: 1px solid transparent;
    margin-bottom: 20px;
}

.art-item-content {
    padding: 20px 10px 10px;
}

.art-item-img {
    overflow: hidden;
    border-radius: 8px;
}

.art-item-img a img {
    width: 100%;
    border-radius: 8px;
}

.art-item-img a img:hover {
    transform: scale(1.05);
}

.art-item-img .art-action-like-count {
    position: absolute;
    right: 39px;
    top: 0;
}

.art-item-img .art-action-collection {
    position: absolute;
    top: 48px;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
}

.art-item-img .place-bid {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    z-index: 5;
    font-size: 15px;
    height: 40px;
    line-height: 40px;
    padding: 0 30px;
}

.art-item-content .place-bid {
    height: 50px;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    margin-top: 35px;
    line-height: 50px;
    text-align: center;
}

.art-name {
    font-size: 22px;
    color: var(--clr-common-heading);
    margin-bottom: 15px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .art-name {
        font-size: 20px;
    }
}

.art-name a:hover {
    color: var(--clr-theme-1);
}

.artist {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.artist .profile-img {
    margin-right: 10px;
}

.artist .profile-img img {
    width: 25px;
    height: 25px;
    border: none;
}

.artist .profile-verification {
    width: 13px;
    height: 13px;
    border-width: 2px;
    line-height: 11px;
    font-size: 6px;
    right: -4px;
    bottom: 0px;
}

.artist-id {
    font-size: 14px;
    line-height: 1;
    color: var(--clr-common-body-text);
    font-weight: 500;
}

.art-meta-type {
    font-size: 15px;
    font-weight: 500;
    color: var(--clr-common-body-text);
    line-height: 1;
    margin-bottom: 5px;
}

.art-activity {
    font-size: 15px;
    font-weight: 500;
    color: var(--clr-common-body-text);
}

.art-activity:hover {
    color: var(--clr-common-heading);
}

.art-activity i {
    margin-right: 10px;
    color: var(--clr-theme-1);
}

.art-price {
    font-size: 18px;
    font-weight: 700;
    color: var(--clr-theme-1);
    line-height: 1;
}

.art-sale {
    font-size: 13px;
    font-weight: 700;
    color: var(--clr-common-heading);
    line-height: 1;
}

.place-bid {
    /*background-image: linear-gradient(to right, #20d083, #18915d, #20d083);*/
    /*background-size: 200% 100%;*/
    /*height: 35px;*/
    /*border-radius: 5px;*/
    /*color: var(--clr-common-white);*/
    /*display: inline-block;*/
    /*line-height: 35px;*/
    /*font-size: 13px;*/
    /*font-weight: 700;*/
    /*padding: 0 10px;*/
}

.place-bid:hover {
    background-position: 100% 100%;
    color: var(--clr-common-white);
}

.art-meta-info {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-top: 0;
}

.art-3dots-icon {
    font-size: 19px;
    color: #878787;
    padding: 0 2px;
}

.art-3dots-menu {
    position: absolute;
    right: 10px;
    top: 20px;
    opacity: 1;
    visibility: visible;
    transition: 0.3s;
    cursor: pointer;
}

.art-3dots-menu::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: transparent;
    z-index: 1;
}

.art-3dots-menu:hover .art-3dots-icon {
    color: var(--clr-theme-1);
}

.show-element .art-3dots-action {
    opacity: 1;
    visibility: visible;
}

.art-3dots-action {
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
}

.art-3dots-action ul {
    background: var(--clr-bg-gray);
    padding: 5px 10px;
    min-width: 100px;
    border-radius: 5px;
    position: absolute;
    right: 0;
    top: 105%;
    z-index: 99999;
}

.art-3dots-action ul li i {
    margin-right: 10px;
    font-size: 12px;
}

.art-3dots-action ul li a {
    font-size: 15px;
    font-weight: 500;
}

.art-3dots-action ul li a:hover {
    color: var(--clr-theme-1);
}

.art-action {
    position: absolute;
    right: 10px;
    top: 10px;
    opacity: 1;
    visibility: visible;
    transition: 0.3s;
    z-index: 1;
}

.art-action:hover .art-action-like-count {
    opacity: 1;
    visibility: visible;
}

.art-action:hover .art-action-collection {
    opacity: 0;
    visibility: hidden;
}

.art-action-like {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    color: var(--clr-common-white);
    font-size: 14px;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    display: inline-block;
}

.art-action-like.liked {
    background: var(--clr-theme-1);
}

.art-action-like-count {
    font-size: 14px;
    font-weight: 600;
    color: var(--clr-common-white);
    height: 40px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    line-height: 40px;
    padding: 0 10px;
    display: inline-block;
}

.art-action-like-count:after {
    position: absolute;
    content: "";
    width: 5px;
    height: 9px;
    background: rgba(0, 0, 0, 0.5);
    right: -5px;
    clip-path: polygon(0 0, 100% 50%, 0 100%);
    top: 50%;
    transform: translateY(-50%);
}

.art-action-collection {
    width: 40px;
    height: 40px;
    font-size: 14px;
    color: var(--clr-common-white);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    text-align: center;
    line-height: 46px;
    display: inline-block;
}

.category-collections-wrapper {
    background: var(--clr-bg-white);
    border-radius: 10px;
    overflow: hidden;
    padding: 8px;
    transition: 0.3s;
    border: 1px solid var(--clr-bg-white);
}

.category-collections-wrapper .art-item-single {
    border-radius: 5px;
    height: 100%;
    overflow: hidden;
}

.category-collections-wrapper .art-item-single:hover {
    box-shadow: none;
}

.category-collections-wrapper .art-item-single:hover .art-item-img a img {
    transform: scale(1);
}

.category-collections-wrapper .art-item-img {
    height: 100%;
}

.category-collections-wrapper .art-item-img a {
    height: 100%;
    pointer-events: none;
}

.category-collections-wrapper .art-item-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.category-collections-wrapper .art-item-wraper {
    padding: 0;
    height: 100%;
    border: 0;
}

.category-collections-wrapper .art-item-inner {
    height: 100%;
}

.category-collections-wrapper .row {
    --bs-gutter-x: 8px;
    --bs-gutter-y: 8px;
}

.category-collections-inner > .row {
    position: relative;
}

.category-collections-inner > .row:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: transparent;
    z-index: 2;
}

.category-collections-inner {
    border-radius: 10px;
    overflow: hidden;
}

.collection-content {
    padding: 16px 12px 12px;
}

.collection-content .art-3dots-menu {
    right: 12px;
    top: 16px;
}

.collection-category .category-name {
    font-size: 22px;
    margin-bottom: 15px;
}

.collection-category .category-name a:hover {
    color: var(--clr-theme-1);
}

.resource-created {
    font-size: 14px;
    color: var(--clr-common-body-text);
    font-weight: 700;
}

.resource-meta-item {
    height: 30px;
    background: var(--clr-bg-white);
    display: inline-block;
    border: 1px solid var(--clr-common-border);
    border-radius: 2px;
    padding: 0 12px;
    transition: 0.3s;
}

.resource-meta-item:hover {
    background-image: linear-gradient(to right, #20d083, #18915d, #20d083);
    background-size: 200% 100%;
    border-image: linear-gradient(to right, #20d083, #18915d, #20d083);
}

.resource-meta-item:hover .resource-created,
.resource-meta-item:hover .resource-meta-type {
    color: var(--clr-common-white);
}

.resource-meta-item .resource-created {
    display: inline-block;
    transition: 0.3s;
}

.resource-meta-item .resource-meta-type {
    display: inline-block;
    transition: 0.3s;
}

.resource-meta-type {
    font-size: 14px;
    color: var(--clr-common-body-text);
    font-weight: 500;
}

.popular-collections-area {
    margin-top: -20px;
}

.in-auction .art-item-wraper {
    border: 1px solid var(--clr-bg-white);
    margin-bottom: 1px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .in-auction .art-item-wraper {
        padding: 20px;
    }
}

.in-auction .art-item-img img {
    height: 440px;
    object-fit: cover;
}

.in-auction .art-item-content {
    margin-top: -100px;
    background: var(--clr-common-white);
    max-width: calc(100% - 10%);
    border-radius: 10px 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 20px 5px;
}

.in-auction .art-meta-info {
    display: grid;
    grid-template-columns: 125px auto;
    justify-content: inherit;
    border-top: 0;
    padding-top: 0;
    margin-top: 27px;
}

@media (max-width: 575px) {
    .in-auction .art-meta-info {
        grid-template-columns: 85px auto;
    }
}

.in-auction .art-price {
    font-size: 18px;
}

@media (max-width: 575px) {
    .in-auction .art-price {
        font-size: 14px;
    }
}

.in-auction .art-meta-type {
    margin-bottom: 8px;
}

.in-auction .artist {
    margin-bottom: 20px;
}

.in-auction .art-3dots-menu {
    right: 20px;
}

@media (max-width: 575px) {
    .in-auction .art-auction-ends {
        font-size: 14px;
    }
}

.art-auction-ends {
    font-size: 18px;
    font-weight: 700;
    color: var(--clr-common-heading);
    display: flex;
    line-height: 1;
}

.art-auction-ends > div {
    margin-right: 5px;
}

.art-auction-ends.in-art-item {
    height: 40px;
    background: rgba(0, 0, 0, 0.5);
    width: inherit;
    padding: 0 15px 0;
    line-height: 40px;
    border-radius: 5px 5px 5px 5px;
    color: var(--clr-common-white);
}

.art-auction-ends.in-art-item:before {
    position: absolute;
    content: "";
    color: var(--clr-theme-1);
    font-family: "Font Awesome 5 Pro";
    left: 14px;
    top: 0px;
    font-weight: 400;
    display: none;
}

.auction-time-and-btn {
    left: 50%;
    transform: translateX(-50%);
    margin-right: -50%;
    position: absolute;
    top: -60px;
}

.auction-time-and-btn .place-bid {
    font-size: 18px;
    font-weight: 700;
    padding: 0 24px;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    left: 50%;
    transform: translateX(-50%);
    margin-right: -50%;
    position: absolute;
    top: 0px;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
}

.circle-nav-btn {
    width: 60px;
    height: 60px;
    border: 1px solid #d6dbdf;
    text-align: center;
    line-height: 58px;
    border-radius: 50%;
    background: var(--clr-common-white);
    font-size: 19px;
    color: var(--clr-common-heading);
    transition: 0.3s;
}

.circle-nav-btn:hover {
    background: var(--clr-theme-1);
    border-color: var(--clr-theme-1);
    color: var(--clr-common-white);
}

.square-nav-btn {
    width: 40px;
    height: 40px;
    border: 1px solid var(--clr-bg-white);
    text-align: center;
    line-height: 40px;
    border-radius: 5px;
    background: var(--clr-bg-white);
    font-size: 19px;
    color: #adb4be;
    transition: 0.3s;
}

.square-nav-btn:hover {
    color: var(--clr-common-white);
    background: var(--clr-theme-1);
    border-color: var(--clr-theme-1);
}

.auction-button-prev {
    position: absolute;
    right: 50px;
    top: -80px;
    transform: translate(0);
    z-index: 2;
}

@media (max-width: 575px) {
    .auction-button-prev {
        display: none;
    }
}

.auction-button-next {
    position: absolute;
    right: 0;
    top: -80px;
    transform: translate(0);
    z-index: 2;
}

@media (max-width: 575px) {
    .auction-button-next {
        display: none;
    }
}

.art-item-single.in-auction:hover {
    box-shadow: none;
}

.new-arrival-btn .text-btn {
    font-size: 16px;
    color: var(--clr-common-heading);
    border-bottom: 1px solid var(--clr-theme-1);
}

.auction-active .art-item-wraper {
    padding: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 575px) {
    .auction-active .art-item-wraper {
        padding: 10px;
    }
}

@media (min-width: 1600px) {
    .container.auction2-container {
        max-width: 1800px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.categories-bar-active .swiper-slide {
    width: auto;
}

.category-item {
    display: inline-block;
}

.category-item a {
    font-size: 14px;
    font-weight: 700;
    color: var(--clr-common-heading);
    border: 1px solid transparent;
    background: var(--clr-bg-white);
    display: inline-flex;
    align-items: center;
    height: 50px;
    line-height: 50px;
    border-radius: 5px;
    padding: 0 23px;
}

.category-item a img {
    max-width: 19px;
    max-height: 19px;
    margin-right: 10px;
}

.category-item a:hover {
    border-color: var(--clr-theme-1);
}

.categories-bar-button-prev {
    font-size: 28px;
    color: var(--clr-common-heading);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 50px;
    line-height: 50px;
    z-index: 2;
}

.categories-bar-button-prev::before {
    position: absolute;
    content: "";
    width: 90px;
    height: 50px;
    background: var(--clr-bg-white);
    opacity: 0.94;
    top: 0;
    left: 0;
    z-index: -1;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .categories-bar-button-prev::before {
        width: 40px;
    }
}

.categories-bar-button-next {
    font-size: 28px;
    color: var(--clr-common-heading);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    height: 50px;
    line-height: 50px;
    z-index: 2;
}

.categories-bar-button-next::before {
    position: absolute;
    content: "";
    width: 90px;
    height: 50px;
    background: var(--clr-bg-white);
    opacity: 0.94;
    top: 0;
    right: 0;
    z-index: -1;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .categories-bar-button-next::before {
        width: 40px;
    }
}

.item-category-select {
    width: 220px;
    height: 50px;
    line-height: 50px;
    border: 1px solid var(--clr-bg-white);
    border-radius: 10px 0 0 10px;
    color: var(--clr-common-heading);
    font-weight: 600;
    background: var(--clr-bg-white);
    border-right-color: var(--clr-common-border);
}

@media (max-width: 575px) {
    .item-category-select {
        border-radius: 10px;
        border-right-color: var(--clr-bg-white);
    }
}

.filter-search-input {
    position: relative;
}

.filter-search-input input {
    width: 100%;
    border: 1px solid var(--clr-bg-white);
    background: var(--clr-bg-white);
    border-radius: 0 10px 10px 0;
    padding: 0 20px;
    outline: none;
}

@media (max-width: 575px) {
    .filter-search-input input {
        border: 1px solid var(--clr-bg-white);
        border-radius: 10px;
    }
}

@media (max-width: 575px) {
    .filter-search-input {
        width: 100%;
        overflow: hidden;
        margin-top: 30px;
        display: inline-block;
    }
}

.filter-search-input button {
    position: absolute;
    right: 20px;
    top: 12px;
    color: #919191;
}

.sale-category-select {
    width: 190px;
    height: 50px;
    line-height: 50px;
    border: 1px solid var(--clr-bg-white);
    border-radius: 0 10px 10px 0;
    color: var(--clr-common-heading);
    background: var(--clr-bg-white);
}

.select-category-title {
    width: 140px;
    display: inline-block;
    height: 50px;
    border: 1px solid var(--clr-bg-white);
    line-height: 50px;
    border-radius: 10px 0 0 10px;
    padding-left: 20px;
    color: var(--clr-common-heading);
    border-right-color: var(--clr-common-border);
    background: var(--clr-bg-white);
}

.select-category-title i {
    margin-right: 4px;
}

.art-filter-row {
    display: grid;
    grid-template-columns: auto 330px;
    grid-gap: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .art-filter-row {
        display: inherit;
    }
}

.art-filter-row .filter-by-search {
    display: grid;
    grid-template-columns: 220px auto;
}

@media (max-width: 575px) {
    .art-filter-row .filter-by-search {
        display: inherit;
    }
}

.art-item-img.art-details-img {
    max-width: 600px;
    height: 1100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .art-item-img.art-details-img {
        height: 1000px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 576px) and (max-width: 767px) {
    .art-item-img.art-details-img {
        height: 800px;
    }
}

@media (max-width: 575px) {
    .art-item-img.art-details-img {
        height: 500px;
    }
}

.art-item-img.art-details-img a {
    height: 100%;
}

.art-item-img.art-details-img img {
    height: 100%;
    object-fit: cover;
}

.created-by {
    font-weight: 600;
    margin-bottom: 20px;
    color: var(--clr-common-body-text);
}

.creator {
    display: grid;
    grid-template-columns: 60px auto;
    grid-gap: 25px;
    align-items: center;
}

.creator .profile-img img {
    width: 60px;
    border: 0;
}

.creator .profile-verification {
    bottom: 0;
    right: -11px;
}

.creator .artist-id {
    font-weight: 600;
}

.art-name-details {
    border-top: 1px solid var(--clr-common-border);
    border-bottom: 1px solid var(--clr-common-border);
    padding: 17px 0 15px 0;
}

.art-name-details .art-name {
    font-size: 48px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 576px) and (max-width: 767px) {
    .art-name-details .art-name {
        font-size: 38px;
    }
}

@media (max-width: 575px) {
    .art-name-details .art-name {
        font-size: 32px;
    }
}

.art-details-meta-info .art-meta-type {
    font-size: 16px;
    margin-bottom: 13px;
}

.art-details-meta-info .art-price {
    font-size: 28px;
    color: var(--clr-common-heading);
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .art-details-meta-info .art-price {
        font-size: 22px;
    }
}

.art-details-meta-info .art-sale {
    font-size: 28px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .art-details-meta-info .art-sale {
        font-size: 22px;
    }
}

.art-details-meta-info .art-auction-ends {
    font-size: 28px;
    width: 200px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .art-details-meta-info .art-auction-ends {
        font-size: 22px;
        width: 170px;
    }
}

.art-details-meta-info .art-meta-notice {
    margin-top: 6px;
}

.art-details-meta-info .artist-meta-item-border::after {
    right: -50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .art-details-meta-info .artist-meta-item-border::after {
        right: -25px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .art-details-meta-info .artist-meta-item-border::after {
        right: -40px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .art-details-meta-info .artist-meta-item-border::after {
        right: -55px;
    }
}

@media (max-width: 575px) {
    .art-details-meta-info .artist-meta-item-border::after {
        display: none;
    }
}

.art-meta-notice {
    font-size: 16px;
    font-weight: 700;
    color: var(--clr-common-heading);
}

.artist-meta-info.art-details-meta-info {
    margin-top: 40px;
    margin-bottom: 40px;
}

@media (max-width: 575px) {
    .artist-meta-info.art-details-meta-info {
        display: inherit;
    }
}

@media only screen and (min-width: 450px) and (max-width: 575px) {
    .artist-meta-info.art-details-meta-info {
        display: flex;
    }
}

.art-item-img .art-action-like-count {
    position: absolute;
    right: 48px;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
}

.art-details-action {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
}

.art-details-action .place-bid {
    height: 50px;
    line-height: 50px;
    padding: 0 45px;
}

.art-details-action .art-action-like-count {
    font-size: 16px;
    font-weight: 600;
    color: var(--clr-common-heading);
    border: 1px solid var(--clr-bg-white);
    height: 50px;
    line-height: 48px;
    padding: 0 15px;
    background: var(--clr-bg-white);
}

.art-details-action .art-action-like-count i {
    margin-right: 10px;
}

.art-details-action .art-action-like-count::after {
    display: none;
}

.art-details-action .art-action-collection {
    font-size: 16px;
    font-weight: 600;
    color: var(--clr-common-heading);
    border: 1px solid var(--clr-bg-white);
    height: 50px;
    line-height: 48px;
    width: inherit;
    padding: 0 15px;
    background: var(--clr-bg-white);
}

.art-details-action .art-action-collection i {
    margin-right: 10px;
}

.social__links.art-item-share {
    display: grid;
    grid-template-columns: 37px auto;
    align-items: center;
}

.social__links.art-item-share ul li {
    margin-right: 13px;
}

.social__links.art-item-share ul li a {
    background: none !important;
    border: none !important;
    color: var(--clr-common-body-text);
    width: inherit;
    height: inherit;
}

.social__links.art-item-share ul li a:hover {
    color: var(--clr-theme-1);
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .art-details-content {
        margin-top: 30px;
    }
}

@media (max-width: 575px) {
    .artist-meta-info.art-details-meta-info .art-meta-item {
        margin-bottom: 20px;
    }
}

.art-information-tab-nav .nav-link {
    font-size: 16px;
    color: var(--clr-common-heading);
    height: 48px;
    padding: 0 5px;
    margin-right: 40px;
    position: relative;
    border: 0;
}

.art-information-tab-nav .nav-link:last-child {
    margin-right: 0px;
}

.art-information-tab-nav .nav-link:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background: var(--clr-theme-1);
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: 0.3s;
}

.art-information-tab-nav .nav-link:hover {
    color: var(--clr-theme-1);
    border: 0;
}

.art-information-tab-nav .nav-tabs {
    border: 1px solid var(--clr-bg-white);
    background: var(--clr-bg-white);
    border-radius: 10px;
    padding: 0 30px;
}

.art-information-tab-nav .nav-link.active {
    color: var(--clr-theme-1);
    border: 0;
    background: none;
}

.art-information-tab-nav .nav-link.active::before {
    opacity: 1;
}

.placed-bids-wrapper {
    border: none;
    border-radius: 10px;
}

.placed-bids-wrapper .single-bid:last-child {
    border-bottom: 0;
    margin-bottom: 0;
}

.single-bid {
    padding: 20px 30px 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--clr-bg-white);
    background: var(--clr-bg-white);
    counter-increment: bid-serial;
    gap: 15px;
    flex-wrap: wrap;
    margin-bottom: 5px;
    border-radius: 10px;
}

@media (max-width: 575px) {
    .single-bid {
        grid-template-columns: 80px auto;
    }
}

@media (max-width: 575px) {
    .single-bid {
        padding: 20px 20px 20px;
    }
}

.single-bid .creator {
    display: grid;
    grid-template-columns: 90px auto;
    grid-gap: 15px;
}

.single-bid .creator .profile-img img {
    width: 90px;
    border: 0;
}

@media (max-width: 575px) {
    .single-bid .creator .profile-img img {
        width: 80px;
    }
}

.single-bid .creator .artist-id {
    font-size: 14px;
    color: var(--clr-theme-1);
    margin-bottom: 16px;
}

.single-bid .artist-name {
    font-size: 18px;
    margin-bottom: 5px;
}

.single-bid .artist-name a:hover {
    color: var(--clr-theme-1);
}

.single-bid .profile-img::before {
    position: absolute;
    content: counter(bid-serial, decimal-leading-zero);
    font-size: 10px;
    font-weight: 600;
    color: var(--clr-common-white);
    background: var(--clr-theme-1);
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 20px;
    border: 3px solid var(--clr-bg-white);
    border-radius: 50%;
    top: 10px;
    left: -13px;
}

.bid-date {
    font-size: 14px;
    line-height: 1;
    color: var(--clr-common-body-text);
}

.bid-time {
    font-size: 14px;
    padding-left: 20px;
    position: relative;
    line-height: 1;
    color: var(--clr-common-body-text);
}

.bid-time:before {
    position: absolute;
    content: "";
    width: 1px;
    height: 100%;
    background: #b9b9b9;
    left: 10px;
}

.bid-date-time {
    display: flex;
}

.bid-status {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 9px;
}

.bid-status span {
    font-weight: 700;
}

.bid-status span.accepted {
    color: #20b17b;
}

.bid-status span.pending {
    color: #ff8a04;
}

.bid-price {
    font-size: 18px;
    font-weight: 700;
    color: var(--clr-common-heading);
    line-height: 1;
    margin-bottom: 8px;
}

.bid-price-dollar {
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
}

.bid-pricing {
    text-align: right;
}

@media (max-width: 575px) {
    .bid-pricing {
        text-align: left;
        padding-left: 95px;
    }
}

.art-info-wrapper {
    border: 1px solid var(--clr-bg-white);
    background: var(--clr-bg-white);
    border-radius: 10px;
    padding: 20px 30px 50px;
}

.art-info-wrapper ul li {
    font-size: 16px;
    font-weight: 700;
    color: var(--clr-common-heading);
    margin-bottom: 0;
}

@media (max-width: 575px) {
    .art-info-wrapper ul li {
        margin-bottom: 10px;
    }
}

.art-info-title {
    font-size: 16px;
    font-weight: 600;
    color: var(--clr-common-body-text);
    width: 240px;
    display: inline-block;
    position: relative;
}

.art-info-title:after {
    position: absolute;
    content: ":";
    right: 50px;
}

.art-user-wrapper {
    border: 1px solid var(--clr-bg-white);
    background: var(--clr-bg-white);
    border-radius: 10px;
    padding: 22px 30px 20px;
}

.art-user-wrapper ul li {
    margin-bottom: 4px;
    padding-left: 25px;
    position: relative;
}

.art-user-wrapper ul li:last-child {
    margin-bottom: 0;
}

.art-user-wrapper ul li:before {
    position: absolute;
    content: "";
    font-family: "Font Awesome 5 Pro";
    left: 0;
    color: var(--clr-theme-1);
}

.note {
    color: var(--clr-common-heading);
    font-weight: 600;
}

.note span {
    font-weight: 700;
    margin-left: 5px;
}

.note .text-btn {
    text-decoration: underline;
    margin-right: 10px;
}

.box-card-icon img {
    height: 64px;
}

.box-card-title {
    font-size: 24px;
    margin-bottom: 25px;
}

.box-card-single {
    border: 1px solid var(--clr-bg-white);
    background: var(--clr-bg-white);
    border-radius: 10px;
    padding: 50px 30px 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 575px) {
    .box-card-single {
        padding: 50px 20px 50px;
    }
}

.box-card-icon {
    margin-bottom: 40px;
}

.box-card-inner p {
    margin-bottom: 30px;
}

.box-card-wallet .box-card-icon {
    margin-bottom: 65px;
    margin-top: -65px;
    position: relative;
}

.box-card-wallet .box-card-icon img {
    height: 70px;
    position: relative;
}

.box-card-wallet .box-card-icon:before {
    position: absolute;
    content: "";
    background: #eef6ff;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    transform: translate(-25%, -25%);
}

.box-card-wallet .box-card-btn a {
    min-width: 180px;
}

.box-card-single.box-card-wallet {
    margin-top: 50px;
}

.box-card-wallet.wallet-metamask .box-card-icon::before {
    background: #fff4ee;
}

.box-card-wallet.wallet-fortmatic .box-card-icon::before {
    background: #eeefff;
}

.box-card-wallet.wallet-torus .box-card-icon::before {
    background: #eff1ff;
}

.box-card-wallet.wallet-bitski .box-card-icon::before {
    background: #ffebf0;
}

.box-card-wallet.wallet-coinbase .box-card-icon::before {
    background: #eff1ff;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .rank-list-container {
        overflow-x: scroll;
        scrollbar-width: thin;
    }
}

.rank-list-wrapper {
    border: 1px solid transparent;
    border-radius: 5px;
    min-width: 930px;
}

.rank-list-row-heading .rank-list-row {
    height: 60px;
}

.rank-list-row-heading .rank-list-cell {
    font-size: 16px;
    font-weight: 700;
    color: var(--clr-common-heading);
}

.rank-list-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--clr-bg-white);
    background: var(--clr-bg-white);
    height: 112px;
    align-items: center;
    margin-bottom: 5px;
    border-radius: 5px;
}

.rank-list-items .rank-list-row {
    counter-increment: rank-serial;
}

.rank-list-items .rank-list-row:last-child {
    border-bottom: 0;
    margin-bottom: 0;
}

.rank-list-items .rank-list-cell-sl span {
    position: relative;
}

.rank-list-items .rank-list-cell-sl span:before {
    content: counter(rank-serial, decimal-leading-zero);
}

.rank-list-items .rank-list-cell-hours {
    color: #2cbe58;
}

.rank-list-items .rank-list-cell-days {
    color: #dc1e1e;
}

.rank-list-cell {
    flex-grow: 1;
    padding-left: 25px;
    font-weight: 600;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .rank-list-cell {
        padding-left: 15px;
    }
}

.rank-list-cell.rank-list-cell-sl {
    max-width: 55px;
}

.rank-list-cell.rank-list-cell-artwotrks {
    max-width: 100px;
}

.rank-list-cell.rank-list-cell-market {
    max-width: 80px;
}

.rank-list-cell.rank-list-cell-volume {
    max-width: 100px;
}

.rank-list-cell.rank-list-cell-hours {
    max-width: 80px;
}

.rank-list-cell.rank-list-cell-days {
    max-width: 90px;
}

.rank-list-cell.rank-list-cell-bids {
    max-width: 55px;
}

.rank-list-cell.rank-list-cell-price {
    max-width: 115px;
}

.rank-list-cell.rank-list-cell-owner {
    max-width: 200px;
}

.rank-list-cell.rank-list-cell-assets {
    max-width: 90px;
}

.art-item-single.art-item-single-rank {
    border-radius: 5px;
    width: 70px;
}

.art-item-single-rank .art-item-wraper {
    border: 0;
    padding: 0;
    border-radius: 5px;
}

.art-item-single-rank .art-item-img img {
    border-radius: 5px;
    width: 70px;
}

.top-seller-select {
    width: 120px;
}

.artwork-filter-row {
    display: grid;
    justify-content: end;
    gap: 15px;
    grid-template-columns: 150px 150px 150px 150px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .artwork-filter-row {
        grid-template-columns: 140px 140px 140px 140px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .artwork-filter-row {
        justify-content: start;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .artwork-filter-row {
        justify-content: start;
        grid-template-columns: 150px 150px 150px;
    }
}

@media (max-width: 575px) {
    .artwork-filter-row {
        justify-content: start;
        grid-template-columns: 135px 135px;
    }
}

@media only screen and (min-width: 450px) and (max-width: 575px) {
    .artwork-filter-row {
        grid-template-columns: 150px 150px;
    }
}

.artworks-area-bg {
    background: var(--clr-bg-gray);
}

.art-item2-single .art-name {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 28px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .art-item2-single .art-name {
        font-size: 24px;
    }
}

.art-item2-single .art-meta-type {
    font-size: 15px;
    color: var(--clr-common-heading);
    font-weight: 400;
    margin-bottom: 13px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .art-item2-single .art-meta-type {
        margin-bottom: 7px;
    }
}

.art-item2-single .art-price,
.art-item2-single .art-auction-ends {
    font-size: 24px;
    font-weight: 600;
    color: var(--clr-common-heading);
}

@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .art-item2-single .art-price,
    .art-item2-single .art-auction-ends {
        font-size: 18px;
    }
}

.art-item2-single .artist .profile-img img {
    width: 50px;
}

.art-item2-single .artist .profile-verification {
    width: 15px;
    height: 15px;
}

.art-item2-single .artist .profile-img {
    margin-right: 15px;
}

.art-item2-single .artist-name {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
}

.art-item2-single .creator-name-id .artist-id {
    font-size: 14px;
    color: var(--clr-common-body-text);
}

.art-item2-single .art-item-wraper {
    padding: 0;
    border: none;
}

.art-item2-single .artist {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding: 15px;
    background: var(--clr-bg-white);
    position: absolute;
    top: -40px;
    left: 40px;
    border-radius: 40px;
    padding-right: 30px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .art-item2-single .artist {
        left: 30px;
    }
}

.art-item2-single .art-item-content {
    padding: 65px 40px 46px;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .art-item2-single .art-item-content {
        padding: 55px 30px 36px;
    }
}

.art-item2-single .art-item-img .place-bid {
    font-size: 15px;
}

.art-item2-single .art-meta-info {
    flex-wrap: wrap;
    gap: 20px;
    column-gap: 100px;
    justify-content: start;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .art-item2-single .art-meta-info {
        column-gap: 0;
        justify-content: space-between;
    }
}

.auction2-button-prev {
    position: absolute;
    top: calc(50% - 15px);
    left: -20px;
    z-index: 1;
    transform: translateY(-50%);
}

@media (max-width: 575px) {
    .auction2-button-prev {
        left: -15px;
    }
}

.auction2-button-next {
    position: absolute;
    top: calc(50% - 15px);
    right: -20px;
    z-index: 1;
    transform: translateY(-50%);
}

@media (max-width: 575px) {
    .auction2-button-next {
        right: -15px;
    }
}

.sidebar-art-list {
    display: flex;
    flex-direction: column;
    height: 460px;
    justify-content: space-between;
}

@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .sidebar-art-list {
        flex-direction: inherit;
        justify-content: space-between;
        gap: 30px;
        flex-wrap: wrap;
        height: inherit;
        margin-top: 30px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
    .sidebar-art-list > div {
        width: calc((100% - 30px) / 2);
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .sidebar-art-list > div {
        width: calc((100% - 0px) / 1);
    }
}

.sidebar-art-item .art-item-img {
    width: 140px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 768px) and (max-width: 991px) {
    .sidebar-art-item .art-item-img {
        width: 110px;
    }
}

.sidebar-art-item .art-item-inner {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.sidebar-art-item .art-name {
    font-size: 20px;
    margin-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .sidebar-art-item .art-name {
        font-size: 18px;
    }
}

.sidebar-art-item .artist-id {
    color: var(--clr-common-heading);
}

.sidebar-art-item .art-meta-type {
    font-size: 12px;
}

.sidebar-art-item .art-price {
    font-size: 16px;
    font-weight: 600;
    color: var(--clr-common-heading);
}

.sidebar-art-item .art-item-content .place-bid {
    font-size: 13px;
    font-weight: 600;
    padding: 0 12px;
    height: 30px;
    line-height: 30px;
    margin-top: 0;
}

.sidebar-art-item .art-meta-info {
    justify-content: space-between;
    align-items: center;
}

.sidebar-art-item .artist {
    margin-bottom: 20px;
}

.sidebar-art-item .art-item-content {
    padding: 0 10px 0;
    flex-grow: 1;
}

.artwork-btn {
    line-height: 1;
}

@media (min-width: 1500px) and (max-width: 1700px) {
    .home-3-artworks .col-xl-3 {
        width: 33.3333333333%;
    }
}

@media (min-width: 1400px) and (max-width: 1499px) {
    .home-3-artworks .col-xl-3 {
        width: 50%;
    }
}

/*----------------------------------------
    08. creator CSS
----------------------------------------*/
.creator-single {
    transition: 0.3s;
    margin-bottom: 30px;
}

.creator-cover-img img {
    width: 100%;
    border-radius: 10px 10px 0 0;
    height: 150px;
    object-fit: cover;
}

.creator-inner {
    border-radius: 10px;
}

.creator-content {
    border: 1px solid var(--clr-bg-white);
    border-radius: 0 0 10px 10px;
    padding: 0px 30px 30px 30px;
    text-align: center;
    background: var(--clr-bg-white);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .creator-content {
        padding: 0px 20px 30px 20px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .creator-content {
        padding: 0px 10px 30px 10px;
    }
}

.artist-meta-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 37px;
}

.follow-artist {
    height: 40px;
    background-image: linear-gradient(to right, #20d083, #18915d, #20d083);
    background-size: 200% 100%;
    display: inline-block;
    line-height: 40px;
    color: var(--clr-common-white);
    font-size: 14px;
    font-weight: 700;
    border-radius: 5px;
    padding: 0 27px;
    padding-left: 47px;
    position: relative;
    transition: 0.3s;
}

.follow-artist:hover {
    background-position: 100% 100%;
}

.follow-artist.followed {
    background: var(--clr-bg-white);
    color: var(--clr-common-body-text);
    border: 1px solid var(--clr-common-border);
}

.creator-info {
    margin-top: -40px;
    z-index: 2;
    margin-bottom: 37px;
    margin-top: -40px;
    z-index: 2;
    margin-bottom: 37px;
}

.creator-info .artist-name {
    font-size: 18px;
    margin-bottom: 4px;
}

.creator-info .artist-name a:hover {
    color: var(--clr-theme-1);
}

.creator-info .artist-id {
    color: var(--clr-theme-1);
    font-size: 14px;
    font-weight: 600;
}

.creator-info .profile-img {
    margin-bottom: 18px;
}

.artist-meta-type {
    font-size: 12px;
    line-height: 1;
    margin-bottom: 7px;
    color: var(--clr-common-body-text);
}

.artist-created {
    font-size: 13px;
    font-weight: 700;
    color: var(--clr-common-heading);
    line-height: 1;
    margin-right: 5px;
}

.artist-likes {
    font-size: 13px;
    font-weight: 700;
    color: var(--clr-common-heading);
    line-height: 1;
}

.artist-follwers {
    font-size: 13px;
    font-weight: 700;
    color: var(--clr-common-heading);
    line-height: 1;
}

.artist-followed {
    font-size: 13px;
    font-weight: 700;
    color: var(--clr-common-heading);
    line-height: 1;
}

.artist-art-collection {
    font-size: 13px;
    font-weight: 700;
    color: var(--clr-common-heading);
    line-height: 1;
}

.artist-art-featured {
    font-size: 13px;
    font-weight: 700;
    color: var(--clr-common-heading);
    line-height: 1;
}

.artist-art-sold {
    font-size: 13px;
    font-weight: 700;
    color: var(--clr-common-heading);
    line-height: 1;
}

.artist-art-bids {
    font-size: 13px;
    font-weight: 700;
    color: var(--clr-common-heading);
    line-height: 1;
}

.artist-meta-item-border {
    position: relative;
}

.artist-meta-item-border:after {
    position: absolute;
    content: "";
    width: 1px;
    height: 100%;
    background: var(--clr-common-border);
    top: -1px;
    right: -25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .artist-meta-item-border:after {
        right: -18px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .artist-meta-item-border:after {
        right: -20px;
    }
}

@media only screen and (min-width: 450px) and (max-width: 575px) {
    .artist-meta-item-border:after {
        right: -75px;
    }
}

.tag-featured {
    font-size: 15px;
    font-weight: 600;
    color: var(--clr-common-white);
    background: #ff7801;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    padding: 0px 12px;
    position: absolute;
    left: 10px;
    top: 10px;
    display: inline-flex;
    align-items: center;
    z-index: 1;
}

.tag-featured i {
    margin-right: 5px;
    font-size: 13px;
}

.creator-single-short {
    transition: 0.3s;
    border-radius: 0px;
}

.creator-single-short.creator-single-filled {
    background: var(--clr-bg-white);
    padding: 30px 30px;
    border-radius: 10px;
}

.creator-short-content {
    display: flex;
    column-gap: 15px;
    align-items: center;
    text-align: inherit;
    border-radius: 10px;
    padding: 0 0;
    background: none;
    border: none;
}

.creator-short-content .creator-info .artist-id {
    font-weight: 400;
}

.creator-short-content .creator-info .artist-name {
    padding-right: 18px;
}

.creator-short-content .profile-img img {
    width: 70px;
    border-width: 4px;
}

.creator-short-content .profile-verification {
    right: 0;
    bottom: 4px;
    width: 12px;
    height: 12px;
    border: 0;
    line-height: 12px;
    font-size: 6px;
}

.creator-short-content .creator-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    margin-top: 0;
    align-items: start;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: 5px;
}

.creator-short-content .creator-info .follow-artist {
    height: 40px;
    line-height: 38px;
    padding: 0 15px;
    padding-left: 15px;
    padding-left: 30px;
    background: var(--clr-bg-white);
    color: var(--clr-common-white);
    background-image: linear-gradient(to right, #20d083, #18915d, #20d083);
    background-size: 200% 100%;
}

.creator-short-content .creator-info .follow-artist:before {
    left: 14px;
    color: var(--clr-common-white);
}

.creator-short-content .creator-info .follow-artist.followed {
    background: var(--clr-bg-white);
    color: var(--clr-common-body-text);
    border: 1px solid var(--clr-common-border);
}

.creator-short-content .creator-info .follow-artist.followed:before {
    content: "";
    color: var(--clr-common-body-text);
}

.creator-short-content .artist-created {
    font-size: 14px;
    font-weight: 600;
    color: var(--clr-common-body-text);
    display: inline;
}

.creator-short-content .artist-meta-type {
    font-size: 14px;
    font-weight: 600;
    color: var(--clr-common-body-text);
    display: inline;
}

.creator-short-content .artist-follwers {
    font-size: 14px;
}

.creator-short-content .artist-meta-item-border:after {
    top: 0;
    left: -20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .creator-short-content .artist-meta-item-border:after {
        left: -12px;
    }
}

.creator-short-content .artist-meta-item-follower {
    padding-top: 18px;
    padding-bottom: 10px;
}

.featured-creator-content {
    border: 0;
    padding: 0;
    text-align: left;
    border-radius: 0;
    display: grid;
    grid-template-columns: 150px auto;
    grid-gap: 30px;
    align-items: center;
}

@media (max-width: 575px) {
    .featured-creator-content {
        display: inherit;
    }
}

.featured-creator-content .profile-img {
    margin-bottom: 50px;
}

.featured-creator-content .profile-img img {
    width: 150px;
    height: 160px;
    border: 0;
    border-radius: 10px;
}

.featured-creator-content .creator-info {
    margin-top: 0;
    margin-bottom: 50px;
}

.featured-creator-content .creator-info .artist-name {
    font-size: 20px;
    display: inline-block;
}

.featured-creator-content .creator-info .artist-id {
    margin-bottom: 6px;
    margin-top: 2px;
}

.featured-creator-content .profile-verification {
    right: -32px;
    bottom: 0;
}

.featured-creator-content .artist-follow-btn {
    position: absolute;
    right: 0;
    top: 0;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .featured-creator-content .artist-follow-btn {
        position: static;
        margin-top: 20px;
    }
}

.featured-creator-content .artist-meta-type {
    font-size: 14px;
    color: var(--clr-common-body-text);
    display: inline;
}

.featured-creator-content .artist-created {
    font-size: 14px;
    color: var(--clr-common-body-text);
    font-weight: 400;
    display: inline;
}

.featured-creator-content .artist-membership-year {
    font-size: 14px;
    font-weight: 400;
    color: var(--clr-common-body-text);
    display: inline;
}

.featured-creator-content .artist-meta-item {
    display: inline-block;
    line-height: 1;
}

.featured-creator-content .artist-meta-item-border {
    padding-left: 20px;
}

.featured-creator-content .artist-meta-item-border:after {
    left: 7px;
    top: 0;
    background: var(--clr-common-body-text);
}

.featured-creator-wrapper {
    border: 1px solid var(--clr-common-white);
    padding: 30px 30px 0 30px;
    border-radius: 15px;
    background: var(--clr-common-white);
}

@media (max-width: 575px) {
    .featured-creator-wrapper {
        padding: 30px 20px 0 20px;
    }
}

.artist-membership-year {
    font-size: 13px;
    font-weight: 700;
    color: var(--clr-common-heading);
    line-height: 1;
}

.featured-creator-btn {
    margin-top: 12px;
}

.featured-creator-arts .art-item-wraper {
    padding: 0;
    border: 0;
}

.featured-creator-arts .art-item-img img {
    max-height: 300px;
    object-fit: cover;
}

.featured-creator-arts .artist-meta-item-border:after {
    right: -50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .featured-creator-arts .artist-meta-item-border:after {
        right: -35px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .featured-creator-arts .artist-meta-item-border:after {
        right: -20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .featured-creator-arts .artist-meta-item-border:after {
        right: -30px;
    }
}

@media (max-width: 575px) {
    .featured-creator-arts .artist-meta-item-border:after {
        right: -15px;
    }
}

.featured-creator-arts .art-meta-info {
    padding-top: 0;
    border-top: 0;
    margin-top: 4px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .featured-creator-arts .art-item-single {
        margin-bottom: 50px;
    }
}

.featured-creator-arts .art-item-single:hover {
    box-shadow: none;
}

.creator-cover-img.creator-details-cover-img img {
    height: 280px;
    border-radius: 0;
}

.creator-cover-img.creator-details-cover-img::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #010430;
    opacity: 0.45;
    top: 0;
    left: 0;
}

.creator-about {
    border: 1px solid var(--clr-bg-white);
    border-radius: 0 0 10px 10px;
    padding: 0 30px 30px;
    background: var(--clr-bg-white);
}

.creator-about .profile-img {
    width: calc(100% + 20px);
    height: 280px;
    background: var(--clr-bg-white);
    border-radius: 10px;
    margin-left: -10px;
    padding: 10px;
    margin-top: -95px;
    margin-bottom: 10px;
}

.creator-about .profile-img img {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 10px;
    object-fit: cover;
}

.creator-about .artist-name {
    font-size: 30px;
    display: inline-block;
    margin-bottom: 8px;
}

.creator-about .profile-verification {
    right: -30px;
    bottom: 3px;
}

.creator-about .artist-id {
    font-size: 18px;
    color: var(--clr-theme-1);
    margin-bottom: 25px;
}

.creator-about ul {
    margin-top: 25px;
    margin-bottom: 30px;
}

.creator-about ul li {
    margin-bottom: 3px;
}

.creator-about ul li i {
    color: #9b9b9b;
    width: 25px;
    display: inline-block;
}

.creator-about ul li a {
    color: var(--clr-theme-1);
}

.creator-about ul li:last-child {
    margin-bottom: 0;
}

.fill-btn.icon-left i {
    margin-right: 10px;
}

.message-creator-btn .fill-btn {
    width: 100%;
    text-align: center;
    padding: 0 20px;
}

.creator-details-meta-info .artist-meta-type {
    font-size: 14px;
}

.creator-details-meta-info .artist-created {
    font-size: 20px;
}

.creator-details-meta-info .artist-likes {
    font-size: 20px;
}

.creator-details-meta-info .artist-follwers {
    font-size: 20px;
}

.creator-details-meta-info .artist-followed {
    font-size: 20px;
}

@media (max-width: 575px) {
    .creator-details-meta-info .artist-meta-item-border::after {
        display: none;
    }
}

.creator-details-action {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.creator-details-action .follow-artist {
    height: 50px;
    line-height: 50px;
}

.social__links.creator-share {
    width: 50px;
    height: 50px;
    border: 1px solid var(--clr-common-border);
    border-radius: 5px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
}

.social__links.creator-share > i {
    color: var(--clr-common-heading);
}

.profile-link-text {
    height: 50px;
    text-overflow: ellipsis;
    max-width: 150px;
    overflow: hidden;
    border: 1px solid var(--clr-common-border);
    display: inline-block;
    line-height: 48px;
    border-radius: 5px;
    padding: 0 50px 0 20px;
    font-weight: 600;
    color: var(--clr-common-heading);
    position: relative;
}

.profile-link-text button {
    color: var(--clr-common-heading);
    font-size: 24px;
    position: absolute;
    right: 15px;
    top: 3px;
}

.creator-info-bar {
    border: 1px solid var(--clr-bg-white);
    background: var(--clr-bg-white);
    padding: 35px 30px 35px;
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .creator-info-bar {
        border-radius: 10px;
    }
}

.artist-meta-info.creator-details-meta-info {
    margin-bottom: 0;
    width: 410px;
    flex-wrap: wrap;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .artist-meta-info.creator-details-meta-info {
        width: 345px;
    }
}

@media (max-width: 575px) {
    .artist-meta-info.creator-details-meta-info {
        gap: 20px;
    }
}

.creator-info-tab-nav .artist-meta-type {
    font-size: 16px;
    font-weight: 600;
    color: var(--clr-common-heading);
    display: inline-block;
}

.creator-info-tab-nav .artist-created {
    font-size: 16px;
    font-weight: 900;
    color: var(--clr-theme-1);
    display: inline-block;
}

.creator-info-tab-nav .artist-art-collection {
    font-size: 16px;
    font-weight: 900;
    color: var(--clr-theme-1);
    display: inline-block;
}

.creator-info-tab-nav .artist-art-featured {
    font-size: 16px;
    font-weight: 900;
    color: var(--clr-theme-1);
    display: inline-block;
}

.creator-info-tab-nav .artist-art-sold {
    font-size: 16px;
    font-weight: 900;
    color: var(--clr-theme-1);
    display: inline-block;
}

.creator-info-tab-nav .artist-art-bids {
    font-size: 16px;
    font-weight: 900;
    color: var(--clr-theme-1);
    display: inline-block;
}

.creator-info-tab-nav .nav-tabs {
    border: 1px solid var(--clr-bg-white);
    background: var(--clr-bg-white);
    border-radius: 10px;
    padding: 9px 40px;
    display: flex;
    justify-content: space-between;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 575px) {
    .creator-info-tab-nav .nav-tabs {
        padding: 9px 30px;
    }
}

.creator-info-tab-nav .nav-link {
    padding: 0px 5px;
    height: 40px;
    border: 0;
    position: relative;
}

.creator-info-tab-nav .nav-link:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background:  #20d083;
    bottom: -9px;
    left: 0;
    opacity: 0;
    transition: 0.3s;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .creator-info-tab-nav .nav-link:before {
        bottom: 5px;
    }
}

.creator-info-tab-nav .nav-link.active {
    color: var(--clr-theme-1);
    border: 0;
    background: none;
}

.creator-info-tab-nav .nav-link.active::before {
    opacity: 1;
}

.creator-info-tab-nav .artist-meta-item-border::after {
    right: -50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .creator-info-tab-nav .artist-meta-item-border::after {
        right: -28px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .creator-info-tab-nav .artist-meta-item-border::after {
        display: none;
    }
}

.collected-items-wrapper .category-collections-wrapper {
    border-radius: 10px;
    padding: 10px;
}

.collected-items-wrapper .category-collections-wrapper .art-item-single {
    border-radius: 5px;
}

.collected-items-wrapper .category-collections-wrapper .art-item-img img {
    border-radius: 5px;
}

.collected-items-wrapper .category-collections-wrapper .row {
    --bs-gutter-x: 5px;
    --bs-gutter-y: 5px;
}

.collected-items-wrapper .collection-content {
    grid-template-columns: 50px auto;
}

.collected-items-wrapper .collection-content .profile-img img {
    width: 50px;
    border-width: 4px;
}

.collected-items-wrapper .collection-category .category-name {
    font-size: 16px;
}

.bid-items {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.bid-items .art-item-single {
    width: 60px;
    border-radius: 10px;
}

@media (max-width: 575px) {
    .bid-items .art-item-single {
        width: 50px;
    }
}

.bid-items .art-item-single:hover .art-item-img a img {
    transform: scale(1);
}

.bid-items .art-item-wraper {
    border: 0;
    padding: 0;
    border-radius: 10px;
}

@media (max-width: 575px) {
    .bid-items {
        gap: 10px;
        max-width: 110px;
    }
}

.bid-items-and-price {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 540px;
    justify-content: space-between;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .bid-items-and-price {
        max-width: 470px;
    }
}

@media (max-width: 575px) {
    .bid-items-and-price .bid-pricing {
        padding-left: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .bids-items-wrapper .single-bid {
        display: inherit;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .bids-items-wrapper .bid-items-and-price {
        max-width: inherit;
        margin-top: 20px;
    }
}

.profile-setting-list {
    border: 1px solid var(--clr-bg-white);
    background: var(--clr-bg-white);
    border-radius: 0 0 10px 10px;
    padding: 30px 30px;
}

.profile-setting-list ul li {
    margin-bottom: 9px;
}

.profile-setting-list ul li span {
    font-size: 16px;
    font-weight: 700;
    color: var(--clr-common-heading);
    display: block;
    cursor: pointer;
}

.profile-setting-list ul li a i {
    margin-right: 15px;
}

.profile-setting-list ul li span:hover {
    color: #20d083;
}

.profile-setting-list ul li:last-child {
    margin-bottom: 0;
}

.profile-setting-list ul li.active span {
    color: #20d083;
}

.creator-info-details .creator-cover-img img {
    height: 160px;
}

.creator-info-details .profile-img img {
    width: 120px;
    height: 130px;
    border: 0;
    border-radius: 10px;
    margin-top: -45px;
}

.creator-img-name {
    display: flex;
    gap: 20px;
    padding: 0 20px 30px 30px;
    border-left: 1px solid var(--clr-bg-white);
    border-right: 1px solid var(--clr-bg-white);
    background: var(--clr-bg-white);
    flex-wrap: wrap;
}

.creator-img-name .artist-name {
    font-size: 24px;
    display: inline-block;
    margin-right: 28px;
}

.creator-img-name .artist-name .profile-verification {
    right: -30px;
}

.creator-img-name .creator-name-id {
    padding-top: 17px;
}

.creator-name-id .artist-id {
    font-size: 15px;
    color: var(--clr-theme-1);
}

.change-photo {
    width: 30px;
    height: 30px;
    background: #171717;
    color: var(--clr-common-white);
    border-radius: 5px;
    text-align: center;
    line-height: 36px;
    position: absolute;
    cursor: pointer;
}

.creator-cover-img .change-photo {
    right: 10px;
    bottom: 10px;
}

.profile-img .change-photo {
    right: 5px;
    bottom: 5px;
}

.personal-info-text {
    min-height: 150px;
    border: 1px solid var(--clr-bg-white);
    border-radius: 5px;
    background: var(--clr-bg-white);
    padding: 15px 20px;
    margin-bottom: 30px;
}

.personal-info-btn {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

.personal-info-btn button {
    min-width: 150px;
}

.top-seller-area {
    position: relative;
    z-index: 2;
}

/*----------------------------------------
    09. register CSS
----------------------------------------*/
.sign-up-area {
    background-size: cover;
    background-position: center;
}

.sign-up-wrapper {
    background: #eff1f5;
    padding: 55px 80px 60px;
    border-radius: 10px;
    overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .sign-up-wrapper {
        padding: 55px 40px 60px;
    }
}

@media (max-width: 575px) {
    .sign-up-wrapper {
        padding: 55px 15px 60px;
    }
}

.sign-up-inner {
    max-width: 690px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .sign-up-inner {
        max-width: 600px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .sign-up-inner {
        max-width: 535px;
    }
}

.sign-up-content h4 {
    font-size: 24px;
    margin-bottom: 13px;
}

.single-input-unit label {
    font-size: 16px;
    font-weight: 700;
    color: var(--clr-common-heading);
    display: block;
    line-height: 1;
    margin-bottom: 12px;
}

.single-input-unit input {
    height: 60px;
    width: 100%;
    border: 1px solid var(--clr-bg-white);
    border-radius: 5px;
    background: var(--clr-bg-white);
    color: var(--clr-common-heading);
    font-size: 16px;
    padding: 0 20px;
    outline: none;
    box-shadow: none;
    margin-bottom: 30px;
}

.single-input-unit input:focus {
    border-color: var(--clr-bg-white);
    background: var(--clr-bg-white);
    box-shadow: 0px 30px 50px rgba(28, 52, 94, 0.1);
    outline: none;
}

.sign-up-btn {
    display: flex;
    align-content: center;
    gap: 30px;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .sign-up-btn {
        flex-direction: column;
    }
}

.sign-up-btn .note {
    line-height: 50px;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .sign-up-btn .note {
        line-height: inherit;
    }
}

.gender-category-select {
    width: 100%;
    height: 60px;
    line-height: 60px;
    border: 1px solid var(--clr-bg-white);
    border-radius: 5px;
    color: var(--clr-common-heading);
    background: var(--clr-bg-white);
    font-size: 16px;
    margin-bottom: 30px;
}

.sign-up-media-single {
    width: 260px;
    height: 60px;
    background: var(--clr-common-white);
    border-radius: 5px;
    display: inline-block;
    padding: 0 15px;
    line-height: 60px;
    font-size: 16px;
    font-weight: 700;
    color: #171717;
    text-align: left;
}

.sign-up-media-single:hover {
    color: var(--clr-theme-1);
}

.sign-up-media-single:focus {
    color: #171717;
}

.sign-up-media-single img {
    width: 33px;
    margin-right: 15px;
}

.text-or {
    font-size: 24px;
    font-weight: 700;
    color: var(--clr-common-white);
    margin-bottom: 35px;
}

.sign-up-with-social {
    width: 34%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 10px 10px 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .sign-up-with-social {
        position: relative;
        width: calc(100% + 160px);
        padding: 50px 0;
        margin-top: 50px;
        margin-left: -80px;
        margin-bottom: -60px;
    }
}

.sign-up-with-social-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-size: cover;
    background-position: center;
}

.sign-up-with-social-bg:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #0421b1;
    opacity: 0.5;
}

.sign-up-with-social-content {
    text-align: center;
}

.sign-up-media {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.login-area {
    background-size: cover;
    background-position: center;
}

.login-wrapper {
    background: #eff1f5;
    padding: 55px 80px 60px;
    border-radius: 10px;
    overflow: hidden;
}

@media (max-width: 575px) {
    .login-wrapper {
        padding: 55px 15px 60px;
    }
}

.login-content h4 {
    font-size: 24px;
    margin-bottom: 40px;
}

.login-btn {
    display: flex;
    align-content: center;
    gap: 20px;
    flex-direction: column;
    text-align: center;
}

.contact-area {
    overflow: hidden;
    padding-top: 120px;
    padding-bottom: 90px;
}

.contact-content h4 {
    font-size: 30px;
    margin-bottom: 13px;
}

@media (max-width: 575px) {
    .contact-content h4 {
        font-size: 28px;
    }
}

.contact-content p {
    max-width: 700px;
}

.subject-category-select {
    width: 100%;
    height: 60px;
    line-height: 60px;
    border: 1px solid var(--clr-common-white);
    border-radius: 5px;
    color: var(--clr-common-heading);
    background: var(--clr-common-white);
    font-size: 16px;
    margin-bottom: 30px;
}

.contact-btn .note {
    margin-bottom: 30px;
}

.contact-btn .fill-btn {
    min-width: 180px;
}

.single-input-unit textarea {
    height: 150px;
    width: 100%;
    border: 1px solid var(--clr-bg-white);
    border-radius: 5px;
    background: var(--clr-bg-white);
    color: var(--clr-common-heading);
    font-size: 16px;
    padding: 15px 20px;
    outline: none;
    box-shadow: none;
    margin-bottom: 30px;
    resize: none;
}

.single-input-unit textarea:focus {
    border-color: var(--clr-theme-1);
    background: var(--clr-common-white);
    box-shadow: 0px 30px 50px rgba(28, 52, 94, 0.1);
    outline: none;
}

.contact-map-wrapper {
    height: 100%;
}

.contact-map {
    position: absolute;
    width: 655px;
    height: calc(100% + 200px);
    top: -120px;
    left: 70px;
}

.contact-map iframe {
    width: 100%;
    height: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .contact-map {
        left: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
    .contact-map {
        position: static;
        width: 100%;
        height: 400px;
    }
}

@media (max-width: 575px) {
    .contact-map {
        position: static;
        width: 100%;
        height: 300px;
    }
}

/*----------------------------------------
    10. process CSS
----------------------------------------*/
.work-processes .row > div {
    counter-increment: process-num;
}

.work-processes .row > div .step {
    font-size: 15px;
    position: absolute;
    right: 40px;
    top: 30px;
    z-index: 2;
}

.work-processes .row > div .step:before {
    position: absolute;
    content: counter(process-num, decimal-leading-zero);
    font-size: 60px;
    color: #eef1f6;
    font-weight: 900;
    z-index: -1;
    right: 50%;
    transform: translateX(50%);
}

.work-process-single {
    background: var(--clr-bg-white);
    border-radius: 10px;
    padding: 55px 40px 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 575px) {
    .work-process-single {
        padding: 45px 30px 35px;
    }
}

.work-process-single:hover .process-icon img {
    transform: scale(1.05);
}

.work-process-content {
    text-align: center;
}

.work-process-content p {
    margin-bottom: 0;
}

.process-icon {
    height: 168px;
    color: var(--clr-theme-1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 575px) {
    .process-icon {
        height: 138px;
    }
}

.process-icon img {
    height: 100%;
    transition: 0.6s;
}

.process-title {
    font-size: 24px;
    margin-bottom: 22px;
}

.process-title a:hover {
    color: var(--clr-theme-1);
}

.text-btn {
    font-size: 14px;
    font-weight: 700;
    color:  #20D083FF;
    line-height: 20px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.text-btn i {
    padding-left: 6px;
}

.text-btn:hover i {
    padding-left: 10px;
}

.process-btn {
    margin-top: 5px;
}

/*----------------------------------------
    11. account CSS
----------------------------------------*/
/* b-creator style */
.b-creator-text p {
    font-size: 18px;
    color: var(--clr-common-white);
    margin-bottom: 0;
}

.b-creator-text h4 {
    font-size: 36px;
    color: var(--clr-common-white);
    margin-bottom: 0;
}

.b-creator-wrapper {
    border-radius: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.b-creator-content {
    padding: 70px 80px 70px 440px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .b-creator-content {
        padding: 70px 80px 70px 360px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .b-creator-content {
        padding: 70px 80px 70px 240px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .b-creator-content {
        padding: 70px 30px 70px 80px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .b-creator-content {
        padding: 70px 30px 70px 30px;
        display: inherit;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .b-creator-btn {
        margin-top: 30px;
    }
}

.b-creator-btn .fill-btn {
    color: var(--clr-common-heading);
    background: var(--clr-common-white);
}

/*----------------------------------------
    12. activity CSS
----------------------------------------*/
.activity-tab-nav .nav-link {
    font-size: 16px;
    color: var(--clr-common-heading);
    height: 48px;
    padding: 0 5px;
    position: relative;
    border: 0;
}

.activity-tab-nav .nav-link:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background: var(--clr-theme-1);
    bottom: -1px;
    left: 0;
    opacity: 0;
    transition: 0.3s;
}

.activity-tab-nav .nav-link:hover {
    color: var(--clr-theme-1);
    border: 0;
}

.activity-tab-nav .nav-tabs {
    border: 1px solid var(--clr-bg-white);
    background: var(--clr-bg-white);
    border-radius: 10px;
    padding: 0 30px;
    width: calc(100% - 360px);
    display: flex;
    justify-content: space-between;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .activity-tab-nav .nav-tabs {
        width: calc(100% - 0px);
    }
}

.activity-tab-nav .nav-link.active {
    color: var(--clr-theme-1);
    border: 0;
    background: none;
}

.activity-tab-nav .nav-link.active::before {
    opacity: 1;
}

.activity-tab-nav nav {
    display: flex;
    justify-content: space-between;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .activity-tab-nav nav {
        display: inherit;
    }
}

.activity-wrapper-actions {
    border: none;
    border-radius: 10px;
}

.activity-wrapper-actions .activity-wrapper-action-single:last-child {
    border-bottom: 0;
    padding: 15px 30px;
}

.activity-wrapper-action-single {
    border-bottom: 1px solid var(--clr-bg-white);
    background: var(--clr-bg-white);
    padding: 15px 30px;
    display: flex;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    margin-bottom: 5px;
}

@media (max-width: 575px) {
    .activity-wrapper-action-single {
        padding: 15px 20px;
    }
}

.activity-wrapper-action-single .profile-img img {
    width: 70px;
    border: 0;
}

.activity-icon {
    width: 30px;
    height: 30px;
    border: 2px solid var(--clr-bg-white);
    background: var(--clr-theme-1);
    text-align: center;
    line-height: 30px;
    color: var(--clr-common-white);
    position: absolute;
    right: -10px;
    bottom: 5px;
    border-radius: 50%;
    font-size: 13px;
}

.actvity-text {
    font-size: 16px;
    font-weight: 600;
    color: var(--clr-common-heading);
    margin-bottom: 5px;
}

.actvity-text a {
    color: var(--clr-theme-1);
}

.activity-time {
    font-size: 14px;
    line-height: 1;
}

.activity-3dots-menu {
    position: absolute;
    right: 25px;
}

@media (max-width: 575px) {
    .activity-3dots-menu {
        right: 18px;
    }
}

.activity-icon.activity-like {
    background: #e93583;
}

.activity-icon.activity-bid {
    background: #22ceb1;
}

.activity-icon.activity-follow {
    background: #0b3f67;
}

.activity-icon.activity-collection {
    background: #ff7c24;
}

.activity-icon.activity-purchase {
    background: var(--clr-theme-1);
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .recent-activity-area {
        padding-top: 60px;
    }
}

.recent-activity-wrapper {
    width: 850px;
    margin: 0 auto;
    padding: 20px 25px;
    background: var(--clr-common-white);
    border-radius: 10px;
    overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .recent-activity-wrapper {
        width: 100%;
    }
}

@media (max-width: 575px) {
    .recent-activity-wrapper {
        padding: 20px 15px;
    }
}

.recent-activity-wrapper .swiper-container-autoheight,
.recent-activity-wrapper .swiper-container-autoheight .swiper-slide {
    height: auto !important;
}

.recent-activity-single {
    text-align: center;
}

@media (max-width: 575px) {
    .recent-activity-single {
        text-align: left;
    }
}

.recent-activity-single .activity-wrapper-action-single {
    display: inline-flex;
    background: none;
    border-radius: 10px;
    gap: 10px;
    padding: 0;
    border: none;
    margin-bottom: 0;
}

.recent-activity-single .activity-wrapper-action-single .profile-img img {
    width: 40px;
    border: 0;
}

.recent-activity-single .activity-meta-text {
    display: flex;
    align-items: center;
}

@media (max-width: 575px) {
    .recent-activity-single .activity-meta-text {
        flex-direction: column;
        gap: 15px;
        align-items: start;
    }
}

.recent-activity-single .actvity-text {
    margin-bottom: 0;
    font-weight: 500;
    color: var(--clr-common-body-text);
    max-width: 600px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .recent-activity-single .actvity-text {
        max-width: 440px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .recent-activity-single .actvity-text {
        max-width: 290px;
    }
}

@media (max-width: 575px) {
    .recent-activity-single .actvity-text {
        white-space: normal;
    }
}

.recent-activity-single .actvity-text a,
.recent-activity-single .actvity-text span {
    font-weight: 600;
    color: var(--clr-common-heading);
}

.recent-activity-single .activity-time {
    font-size: 16px;
    font-weight: 500;
    padding-left: 20px;
    border-left: 1px solid var(--clr-common-border);
    margin-left: 20px;
}

@media (max-width: 575px) {
    .recent-activity-single .activity-time {
        padding-left: 0;
        border-left: none;
        margin-left: 0;
        padding-bottom: 3px;
    }
}

/*----------------------------------------
    13. forum CSS
----------------------------------------*/
.forum-search-bar .filter-by-search {
    display: grid;
    grid-template-columns: 220px auto;
}

@media (max-width: 575px) {
    .forum-search-bar .filter-by-search {
        display: inherit;
    }
}

.forum-search-bar .question-category-select {
    height: 60px;
    line-height: 60px;
    border-right-color: var(--clr-common-border);
}

@media (max-width: 575px) {
    .forum-search-bar .question-category-select {
        border-right-color: var(--clr-bg-white);
    }
}

.forum-search-bar .filter-search-input input {
    height: 60px;
}

.forum-search-bar .filter-search-input button {
    top: 18px;
}

.question-category-select {
    width: 220px;
    height: 50px;
    line-height: 50px;
    border: 1px solid var(--clr-bg-white);
    border-radius: 10px 0 0 10px;
    color: var(--clr-common-heading);
    font-weight: 700;
}

@media (max-width: 575px) {
    .question-category-select {
        border-radius: 10px;
    }
}

.create-question-btn {
    height: 60px;
    background: var(--clr-theme-1);
    width: 100%;
    display: block;
    text-align: center;
    border: 1px solid var(--clr-theme-1);
    border-radius: 10px;
    line-height: 58px;
    color: var(--clr-common-white);
    font-weight: 700;
    position: relative;
}

.create-question-btn:before {
    position: absolute;
    content: "";
    font-family: "Font Awesome 5 Pro";
    width: 40px;
    height: 40px;
    left: 10px;
    top: 10px;
    background: #4756e3;
    border-radius: 5px;
    font-weight: 500;
    line-height: 40px;
}

.create-question-btn:hover {
    color: var(--clr-common-white);
}

.tag {
    height: 40px;
    border: 1px solid var(--clr-common-border);
    padding: 0 10px;
    border-radius: 5px;
    display: inline-block;
    line-height: 38px;
    font-size: 16px;
    font-weight: 600;
    color: var(--clr-common-body-text);
    background: var(--clr-bg-white);
    text-align: center;
}

.tag:hover {
    color: var(--clr-theme-1);
    border-color: var(--clr-theme-1);
}

.sidebar-widget-single .tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.sidebar-widget-single .tags-list .tag {
    flex-grow: 1;
    max-width: 180px;
}

.auction-button-next.sidebar-auction-next {
    color: var(--clr-common-heading);
    font-size: 19px;
    top: -60px;
    transform: none;
}

.auction-button-next.sidebar-auction-next:hover {
    color: var(--clr-theme-1);
}

.auction-button-prev.sidebar-auction-prev {
    color: var(--clr-common-heading);
    font-size: 19px;
    top: -60px;
    left: auto;
    right: 26px;
    transform: none;
}

.auction-button-prev.sidebar-auction-prev:hover {
    color: var(--clr-theme-1);
}

.in-sidebar-auction .art-item-wraper {
    border: 0;
    padding: 0;
}

.q-single-wrapper {
    border: none;
    background: var(--clr-bg-white);
    border-radius: 10px;
}

.q-single-content {
    padding: 40px 40px 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 575px) {
    .q-single-content {
        padding: 40px 15px 15px;
    }
}

.q-single-content .profile-img img {
    width: 70px;
    border: 0;
}

.author-name-time {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;
}

.name-post-time .artist-name {
    font-size: 20px;
    margin-bottom: 7px;
}

.post-date-time {
    display: flex;
    gap: 20px;
}

.post-date {
    font-size: 14px;
    color: var(--clr-common-body-text);
    line-height: 1;
}

.post-time {
    font-size: 14px;
    color: var(--clr-common-body-text);
    line-height: 1;
}

.item-border-before {
    position: relative;
}

.item-border-before:before {
    position: absolute;
    content: "";
    width: 1px;
    height: 100%;
    background: #b9b9b9;
    left: -10px;
    top: -1px;
}

.post-question {
    font-size: 24px;
    margin-bottom: 13px;
}

@media (max-width: 575px) {
    .post-question {
        font-size: 22px;
    }
}

.post-inner-tags .tag {
    color: var(--clr-theme-1);
    font-size: 15px;
    font-weight: 400;
    padding: 0;
    height: inherit;
    border: 0;
    background: none;
    line-height: 1;
    border-radius: 0;
}

.tags-list.post-inner-tags {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    margin-top: 25px;
    margin-bottom: 8px;
}

.q-meta-type {
    font-size: 15px;
    color: var(--clr-common-heading);
    line-height: 1;
}

.q-meta-likes {
    font-size: 15px;
    color: var(--clr-common-heading);
    line-height: 1;
}

.q-meta-comments {
    font-size: 15px;
    color: var(--clr-common-heading);
    line-height: 1;
}

.q-meta-shares {
    font-size: 15px;
    color: var(--clr-common-heading);
    line-height: 1;
}

.q-meta-icon {
    font-size: 17px;
    color: var(--clr-common-heading);
    line-height: 1;
    margin-right: 5px;
}

.q-meta-item {
    display: flex;
    gap: 5px;
    align-items: center;
}

.q-meta-item a {
    display: flex;
    gap: 5px;
    align-items: center;
}

.q-meta-item button {
    display: flex;
    gap: 5px;
    align-items: center;
}

.q-meta-views {
    font-size: 15px;
    color: var(--clr-common-heading);
    line-height: 1;
}

.q-meta-viewed-member {
    padding-left: 19px;
    line-height: 1;
    margin-right: 5px;
}

.q-meta-viewed-member .profile-img img {
    width: 32px;
    border-width: 3px;
    margin-left: -19px;
}

.q-meta-content {
    display: flex;
    flex-wrap: wrap;
}

.q-meta-content .q-meta-item {
    border-top: 1px solid var(--clr-common-border);
    border-right: 1px solid var(--clr-common-border);
    height: 50px;
    flex-grow: 1;
    justify-content: center;
    padding: 0 10px;
}

.q-meta-content .q-meta-item:last-child {
    border-right: 0;
}

@media only screen and (min-width: 450px) and (max-width: 575px) {
    .q-meta-content .q-meta-item:nth-child(3) {
        border-right: 0;
    }
}

.comments-show .q-meta-content .q-meta-item {
    border-bottom: 1px solid var(--clr-common-border);
}

@media only screen and (min-width: 450px) and (max-width: 575px) {
    .comments-show .q-meta-content .q-meta-item:last-child {
        border-top: 0;
    }
}

.q-write-answer {
    padding: 30px 40px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 575px) {
    .q-write-answer {
        padding: 30px 15px;
    }
}

.q-write-answer .profile-img img {
    width: 60px;
    border: 0;
}

.q-write-answer .answer-submit {
    flex-grow: 1;
}

.answer-submit textarea {
    border: 1px solid var(--clr-common-border);
    background: var(--clr-bg-white);
    border-radius: 10px;
    height: 150px;
    width: 100%;
    resize: none;
    padding: 15px 20px;
    margin-bottom: 20px;
}

.answer-submit-btn {
    text-align: right;
}

.q-answers {
    padding: 0 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
(max-width: 575px) {
    .q-answers {
        padding: 0 15px;
    }
}

.q-answers .q-single-answer:last-child {
    border-bottom: 0;
}

.q-single-answer {
    border-bottom: 1px solid var(--clr-common-border);
    margin-bottom: 30px;
}

.q-single-answer .profile-img img {
    width: 50px;
    border: 0;
}

.q-single-answer .name-post-time .artist-name {
    font-size: 18px;
}

.q-single-answer .post-date {
    font-size: 12px;
}

.q-single-answer .post-time {
    font-size: 12px;
}

.q-single-answer .author-name-time {
    gap: 10px;
}

.ans-meta-content {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.ans-meta-content .q-meta-item {
    padding-right: 20px;
    border-right: 1px solid #e3e3e3;
}

.ans-meta-content .q-meta-item:last-child {
    padding-right: 0;
    border-right: 0;
}

.ans-meta-content .q-meta-item button * {
    color: var(--clr-theme-1);
}

.ans-meta-content .q-meta-icon {
    font-size: 15px;
}

.ans-meta-content .q-meta-likes {
    font-size: 14px;
}

.ans-meta-content .q-meta-type {
    font-size: 14px;
}

.answer-text {
    margin-bottom: 15px;
}

.q-answers-btn {
    text-align: center;
    margin-top: 60px;
    padding-bottom: 30px;
}

/*----------------------------------------
    14. footer CSS
----------------------------------------*/

/*----------------------------------------
    15. body-color CSS
----------------------------------------*/
.body-bg {
    background: #eff1f5;
}

.body-bg.dark,
.body-bg.active-dark-mode {
    background: #191c1f;
}

.body-bg.white {
    background: var(--clr-common-white);
}

.active-dark-mode .work-processes .row > div .step::before {
    color: #27303e;
}

.active-dark-mode .header1-logo .logo-bw {
    display: block;
}

.active-dark-mode .header1-logo .logo-bb {
    display: none;
}

.active-dark-mode .footer-logo .logo-bw {
    display: block;
}

.active-dark-mode .footer-logo .logo-bb {
    display: none;
}

.active-dark-mode .sticky {
    background: #000;
}

.active-dark-mode .header-main2.sticky {
    background: none;
}

.active-dark-mode .home3-mode-switch .label .fa-moon {
    display: none;
}

.active-dark-mode .sign-up-wrapper,
.active-dark-mode .login-wrapper {
    background: var(--clr-bg-footer);
}

.active-dark-mode .copyright-support-lines::before {
    background: #222c3a;
}

.active-dark-mode .copyright-support-lines::after {
    background: #222c3a;
}

.active-dark-mode .copyright-text {
    color: var(--clr-common-white);
}

.active-dark-mode .subscribe-form input {
    background: #1c2434;
}

.active-dark-mode .social__links ul li a {
    background: #191c1f;
    border: 1px solid #191c1f;
}

.active-dark-mode .social__links ul li a:hover {
    background: #20D083FF;
    border-color: #20D083FF;
    color: var(--clr-common-white);
}

.active-dark-mode .menu2-sidebar-widget .work-process-single {
    background: #1c2434;
}

.active-dark-mode .menu2-side-bar {
    background: #181f2d;
}

.active-dark-mode .menu2-side-bar {
    border-color: #262e3e;
}

.active-dark-mode .menu2-mobile-menu {
    border-color: #262e3e;
}

.active-dark-mode .sidebar-creators-list .creator-single-short {
    border-color: #262e3e;
}

.active-dark-mode .header-main2 {
    background: #191c1f;
}

.active-dark-mode .sidebar-category-filter {
    border-color: #262e3e;
}

.active-dark-mode .home3-mode-switch .label {
    border-color: #39404e;
}

.active-dark-mode
.header-main2-content
.filter-search-input.header-search
input {
    background: #1c2434;
}

.active-dark-mode .header-main2-content {
    background: #181f2d;
}

.active-dark-mode
.menu2-mobile-menu.mean-container
.mean-nav
ul
li
a.mean-expand {
    background: #181f2d;
}

.active-light-mode .home3-mode-switch .label .fa-sun {
    display: none;
}

.active-light-mode .filter-search-input.header-search input {
    border: 1px solid #d6d6d6;
}

.mode-switch-wrapper {
    display: block;
    width: auto;
    transition: 0.3s;
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .mode-switch-wrapper {
        right: -9px;
    }
}

.checkbox {
    opacity: 0;
    position: absolute;
}

.label {
    background-color: #111;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    height: 26px;
    width: 50px;
    transform: scale(1.4);
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
    .label {
        transform: scale(1.2);
    }
}

.active-dark-mode .label .ball {
    background-color: var(--clr-common-white);
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    height: 22px;
    width: 22px;
    transform: translateX(0px);
    transition: transform 0.2s linear;
}

.active-light-mode .label .ball {
    background-color: var(--clr-common-white);
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    height: 22px;
    width: 22px;
    transform: translateX(24px);
    transition: transform 0.2s linear;
}

.active-light-mode .checkbox:checked .ball {
    transform: translateX(0px);
}

.active-dark-mode .checkbox:checked .ball {
    transform: translateX(24px);
}

.fa-moon {
    color: #f1c40f;
}

.fa-sun {
    color: #f39c12;
}

.header1-logo .logo-bw {
    display: none;
}

.footer-logo .logo-bw {
    display: none;
}

/*----------------------------------------
    16. wallet CSS
----------------------------------------*/
.wallet-col-width-20 {
    width: 20%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .wallet-col-width-20 {
        width: 33.3333%;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .wallet-col-width-20 {
        width: 50%;
    }
}

@media (max-width: 575px) {
    .wallet-col-width-20 {
        width: 100%;
    }
}

.bpw-nft-wallet {
    background: var(--clr-bg-white);
    border-radius: 10px;
    padding: 35px 15px 30px 15px;
}

.bpw-nft-wallet:hover .bpw-nft-wallet-title {
    color: var(--clr-common-heading);
}

.bpw-nft-wallet-icon {
    margin-bottom: 15px;
}

.bpw-nft-wallet .bpw-nft-wallet-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--clr-common-body-text);
    margin-bottom: 0px;
}

.bpw-nft-category {
    text-align: center;
}

.bpw-nft-category-img {
    overflow: hidden;
    border-radius: 13px;
    margin-bottom: 25px;
}

.bpw-nft-category-img img {
    width: 100%;
}

.bpw-nft-category .bpw-nft-category-title {
    font-size: 18px;
    color: var(--clr-common-heading);
    font-weight: 700;
}

.bpw-nft-category .bpw-nft-category-title a:hover {
    color: var(--clr-theme-1);
}

.bpw-nft-category:hover img {
    transform: scale(1.1);
}

.bpw-nft-category-area {
    background: var(--clr-bg-gray);
}
