.single-banner {
  background: #eff1f5;

  .nfts {
    color: #20d083;
  }

  .banner-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%
  }
}