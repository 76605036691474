.footer1-bg {
  background: var(--clr-bg-footer);
}

.footer-area1-bg {
  background: #f9f9f9;
}

.footer-area {
  padding-top: 100px;
  padding-bottom: 50px;
}

.footer-logo {
  max-width: 160px;
  margin-bottom: 30px;
}

.under-logo-text {
  margin-bottom: 35px;
}

.footer-widget-title h4 {
  font-size: 20px;
  font-weight: 700;
  color: var(--clr-common-heading);
  margin-bottom: 35px;
}

.footer-widget ul li {
  margin-bottom: 10px;
}

.footer-widget ul li a {
  font-size: 16px;
  color: var(--clr-common-body-text);
}

.footer-widget ul li a:hover {
  color: var(--clr-common-heading);
}

.footer-widget ul li:last-child {
  margin-bottom: 0;
}

.footer-widget p {
  font-size: 16px;
  color: var(--clr-common-body-text);
}

.social__links.footer__social ul {
  display: inline-flex;
  gap: 10px;
}

.social__links.footer__social ul li {
  margin-bottom: 0;
}

.footer1-widget1 {
  max-width: 335px;
  margin-right: -35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .footer1-widget1 {
    margin-right: 0;
  }
}

.footer1-widget2 {
  margin-left: auto;
  max-width: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .footer1-widget2 {
    margin-left: 0;
  }
}

.footer1-widget3 {
  margin-left: auto;
  max-width: 165px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .footer1-widget3 {
    margin-left: 0;
  }
}

.footer1-widget4 {
  margin-left: auto;
  max-width: 185px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .footer1-widget4 {
    margin-left: 0;
  }
}

.subscribe-form {
  position: relative;
  width: 100%;
  max-width: 480px;
}

.subscribe-form::before {
  position: absolute;
  content: "";
  top: 21px;
  color: #86909d;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  left: 19px;
}

.subscribe-form input {
  width: 100%;
  height: 70px;
  line-height: 60px;
  padding: 0 180px 0 45px;
  color: var(--clr-common-heading);
  border-radius: 5px;
  background: #eff1f5;
  border: none;
  outline: none;
}

@media (max-width: 575px) {
  .subscribe-form input {
    padding: 0 20px 0 45px;
  }
}

.subscribe-form input::placeholder {
  font-size: 14px;
  color: #86909d;
}

.subscribe-form input:focus {
  border: 0;
}

.subscribe-form button {
  font-size: 16px;
  font-weight: 700;
  text-transform: inherit;
  line-height: 50px;
  text-align: center;
  height: 50px;
  background-image: linear-gradient(to right, #20d083, #18915d, #20d083);
  background-size: 200% 100%;
  color: var(--clr-common-white);
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 0;
  border-radius: 5px;
  overflow: hidden;
  padding: 0 30px;
}

@media (max-width: 575px) {
  .subscribe-form button {
    position: static;
    margin-top: 20px;
  }
}

.subscribe-form button:hover {
  background-position: 100% 100%;
}

.subscribe-form button i {
  color: var(--clr-common-white);
  margin-left: 10px;
}

.irc-item {
  display: flex;
}

.irc-item-icon {
  margin-right: 20px;
}

.irc-item-icon i {
  font-size: 50px;
  color: var(--clr-theme-1);
}

.irc-item-content p {
  font-size: 14px;
  color: var(--clr-common-body-text);
  margin-bottom: 0;
}

.irc-item-content a {
  font-size: 18px;
  font-weight: 700;
  color: var(--clr-common-heading);
}

.copyright-wrapper {
  display: flex;
}

.copyright-text {
  a {
    color: #20D083FF !important;
  }
}

.copyright1-inner {
  background: #fff;
  padding: 30px 30px 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .copyright1-inner {
    padding: 30px 50px 30px;
  }
}

@media (max-width: 575px) {
  .copyright1-inner {
    padding: 30px 15px 30px;
  }
}

.copyright2-inner {
  padding: 22px 30px;
  background: var(--clr-bg-footer);
  border-radius: 10px 10px 0 0;
}

.irc-item.copyright-support {
  align-items: center;
  display: flex;
}

.copyright1-support {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 575px), only screen and (min-width: 576px) and (max-width: 767px) {
  .copyright1-support {
    margin-left: 0;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.copyright-text {
  color: var(--clr-common-body-text);
  font-size: 16px;
}

.copyright-text a {
  color: var(--clr-theme-1);
}

.copyright1-text {
  max-width: 196px;
}

@media (max-width: 575px), only screen and (min-width: 576px) and (max-width: 767px) {
  .copyright1-text {
    max-width: inherit;
  }
}

.copyright2-text {
  max-width: 196px;
}

@media (max-width: 575px), only screen and (min-width: 576px) and (max-width: 767px) {
  .copyright2-text {
    max-width: inherit;
    margin: 10px 0;
  }
}

.copyright-support-lines {
  position: relative;
  height: 70px;
}

.copyright-support-lines::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 70px;
  background: var(--clr-common-border);
  top: 0;
  left: -75px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .copyright-support-lines::before {
    left: -40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .copyright-support-lines::before {
    left: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .copyright-support-lines::before {
    left: -55px;
  }
}

@media (max-width: 575px), only screen and (min-width: 576px) and (max-width: 767px) {
  .copyright-support-lines::before {
    display: none;
  }
}

.copyright-support-lines::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 70px;
  background: var(--clr-common-border);
  top: 0;
  right: -15px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .copyright-support-lines::after {
    right: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .copyright-support-lines::after {
    display: none;
  }
}

@media (max-width: 575px), only screen and (min-width: 576px) and (max-width: 767px) {
  .copyright-support-lines::after {
    display: none;
  }
}

.subscribe-form-copyright1 {
  margin-left: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-form-copyright1 {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
}

@media (max-width: 575px) {
  .subscribe-form-copyright1 {
    margin-left: 0;
  }
}

.social__links.footer__social ul li a {
  color: var(--clr-common-heading);
}

.social__links.footer__social ul li a:hover {
  color: var(--clr-bg-white);
}

.ball {
  background-color: var(--clr-common-white);
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  height: 22px;
  width: 22px;
  transform: translateX(24px);
  transition: transform .2s linear;
}